import React, { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { LogoutIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation, useNavigate, useHistory } from "react-router-dom";
import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import Search from "./Search";
import logohorizontal from "../assets/images/logos/adsteak_h_light.svg";
import logoSteak from "../assets/images/logos/adsteak_v_light.svg";
import API from "../API";
import UnsubscribeModal from "../pages/payment/UnsubscribeModal";
import { useStripeInit } from "../hooks";


export default function NavBar({ handleSearch }) {
  const [showNav, setShowNav] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  
  const [userRequestCount, setUserRequestCount] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  // const [sessionUrl, setSessionUrl] = useState(null);
  const [userInfo, setUserInfo] = useState();

  const [subscription, sessionUrl] = useStripeInit();

  const navigation = [
    { name: "Ads Search", href: "/", current: true },
    { name: "Aliexpress Products", href: "/aliexpress_products", current: false },
    { name: "Products Research", href: "/products_Research", current: false },
    { name: "Ads Staff Picks", href: "/ads_staff_picks", current: false },
    { name: "Favorites", href: "/favorites", current: false },
  ];


  // useEffect(() => {
  //   createStripePortalSession()
  //   getUserInfo()
  //   return () => {

  //   };
  // }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // useEffect(() => {
  //   console.log('userInfo' + JSON.stringify(userInfo))
  //   console.log('user' + JSON.stringify(user))
  //   console.log('tuser' + typeof user)
  //   // console.log('user' + JSON.stringify(user['']))
  //   return () => {

  //   };
  // }, []);

  if (user?.role?.includes("admin")) {
    navigation.push({ name: "Admin", href: "/admin", current: false });
    navigation.push({ name: "Draft", href: "/draft", current: false });
  }

  // async function createStripePortalSession(){
  //   try {
  //     const res = await API.post(`stripe/create-session`, { 
  //       data: {
  //         user_id: user._id,
  //         email: user.email
  //       }
  //     });
  //     setSessionUrl(res?.data?.session?.url)
  //     console.log(res, 'res')
  //   } catch (error) {
  //     console.log(error, 'session response error')
  //   }
  // }
  // const saveAuthenticatedUser = async () => {
  //   try {
  //     const res = await API.get(`user/${user._id}`, {
  //       headers: {
  //         Authorization: `Bearer ${await getAccessTokenSilently()}`,
  //       },
  //     });
  //     console.log(res, 'res in getuser')
  //     setUserInfo(res?.data?.user)
  //     setSubscriptionStatus(res?.data?.subscriptionStatus)
  //     setUserRequestCount(res?.data?.userRequestCount)
  //     console.log('userRequestCount' + JSON.stringify(res?.data?.userRequestCount))
  //   } catch (error) {
  //     console.log(error, 'error in saving authenticated user')
  //   }
  // }
  const handleCancelSubscription = async () => {
    try {
      const res = await API.post(`stripe/cancel-subscription`, {
        userId: user._id,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSubscriptionStatus(res?.data?.subscription?.status)
      setViewModal(false)
    } catch (error) {
      // setError('Failed to cancel subscription');
    }
  };
  const handleResumeSubscription = async () => {
    try {
      const res = await API.post(`stripe/resume-subscription`, {
        userId: user._id,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSubscriptionStatus(res?.data?.subscription?.status)
    } catch (error) {
      console.log(error, 'failed to resume subscription')
      // setError('Failed to cancel subscription');
    }
  };
  // function classNames(...classes) {
  //   return classes.filter(Boolean).join(' ')
  // }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Disclosure as="nav"
        className={`bg-white  pt-1 w-full fixed top-0 z-50 ${showNav && "shadow-md"}`}
      // style={{position:showNav ? 'fixed' :'relative' }}
      // className="bg-gray-800"
      >
        {({ open }) => (
          <>
            <div className={`max-w-7xl mx-auto px-2 md:px-6 lg:px-8`} >
              <div className="relative flex items-center justify-between h-14 " >
                <div className="absolute inset-y-0 left-0 flex items-center [@media(min-width:976px)]:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400
                 hover:text-white hover:bg-[#2cbad0] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center [@media(min-width:976px)]:items-stretch [@media(min-width:976px)]:justify-start" >
                  <Link to="/" id="logo" className="flex-shrink-0 flex items-center" onClick={scrollToTop}>
                    <img
                      className="block md:hidden h-12 w-auto"
                      src={logoSteak}
                      alt="Workflow"
                    />
                    <img
                      className="hidden md:block h-12 w-auto"
                      src={logohorizontal}
                      alt="Workflow"
                    />
                  </Link>
                  <div className="hidden [@media(min-width:976px)]:block md:ml-6">
                    <div className="flex space-x-4 mb-2.5">
                      {navigation.map((link) => (
                        <Link
                          key={link.name}
                          to={link.href}
                          className={`${location.pathname === link.href
                            ? "bg-[#EEF6F7] text-[#17A2B8] border-b-2 border-b-[#17A2B8]"
                            : "text-gray-700  hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]"
                            } px-3 py-2  text-md font-medium`}
                          onClick={scrollToTop}
                        >
                          {link.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                {/* {location.pathname !== "/favorites" && (
                <div className="flex-1 px-2 lg:flex justify-center w-100  hidden lg:ml-6 lg:justify-end">
                  <Search handleSearch={handleSearch} />
                </div>
              )} */}

                <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-[#17A2B8] flex text-md rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.picture}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute divide-y right-0 mt-2 w-80   rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
                        <Menu.Item>
                          <div className="flex flex-row gap-3 items-center px-4 py-3">
                            <img
                              className="h-8 w-8 rounded-full"
                              src={user.picture}
                              alt=""
                            />
                            <div>
                              <h4>{user.email}</h4>
                              <p className="text-gray-400 text-sm">{user.name}</p>
                            </div>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="flex flex-row gap-3 items-center px-4 py-3">
                            <p className=" text-md">Member :</p>
                            <p className="font-bold">{subscription ? subscription.nickname : 'Free'}</p>
                          </div>
                        </Menu.Item>
                        {/* <Menu.Item>
                          <div className="flex flex-row gap-3 items-center px-4 py-3">
                            <p className=" text-md">Expiry Date:</p>
                            <p className="font-bold">{user.role[0] === 'trial' ? 'Free forever' : new Date(userInfo?.subscription?.current_period_end * 1000).toLocaleDateString()}</p>
                          </div>
                        </Menu.Item>
                        {user.role[0] !== 'trial' && (
                          <Menu.Item>
                            <div className="flex flex-row gap-3 items-center px-4 py-3">
                              <p className=" text-md">Subscription Status:</p>
                              <p className="font-bold">{subscriptionStatus || userInfo?.subscription?.status}</p>
                            </div>
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          <div className="flex flex-row gap-3 items-center justify-between px-4 py-3">
                            <p className="text-sm">Ads per query</p>
                            <p className="text-sm">{user.role[0] === 'starter' ? 200 : user.role[0] === 'vip' ? 1000 : user.role[0] === 'pro' ? 2000 : '12'}</p>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="flex flex-row gap-3 items-center justify-between px-4 py-3">
                            <p className="text-sm">Ad analytics viewed today:</p>
                            <p className="text-sm">{userRequestCount?.adsAnalyticCount || 0} / {user.role[0] === 'starter' ? 50 : user.role[0] === 'vip' ? 200 : user.role[0] === 'pro' ? 600 : '5'}</p>
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div className="flex flex-row gap-3 items-center justify-between px-4 py-3">
                            <p className="text-sm">Product analytics viewed today:</p>
                            <p className="text-sm">{userRequestCount?.productAnalyticCount || 0} / {user.role[0] === 'starter' ? 50 : user.role[0] === 'vip' ? 200 : user.role[0] === 'pro' ? 600 : '12'}</p>
                          </div>
                        </Menu.Item> */}
                        <Menu.Item>
                          {({ close }) => {
                            // console.log(subscriptionStatus, 's stat')
                            return (
                            <>
                              {/* {subscriptionStatus === 'active' ? (
                                <button 
                                  onClick={() => {
                                    setViewModal(true)
                                    close()
                                  }} 
                                  className={'w-full px-5'}>
                                  <p className="w-full py-3  bg-red-600 my-4 rounded-xl text-white">
                                    <span className="font-medium">
                                      Unsubscribe
                                    </span>
                                  </p>
                                </button>
                              ) : subscriptionStatus === 'canceled' && (
                                  <button onClick={handleResumeSubscription} className={'w-full px-5'}>
                                    <p className="w-full py-3  bg-orange-500 my-4 rounded-xl text-white">
                                      <span className="font-medium">
                                        Resume
                                      </span>
                                    </p>
                                  </button>
                                )
                              }
                              {user?.role[0] === 'trial' && (
                                <button onClick={() => {
                                  close()
                                  navigate("/payment")
                                }} className={'w-full px-5'}>
                                  <p className="w-full py-3  bg-[#17A2B8] my-4 rounded-xl text-white">
                                    <span className="font-medium">
                                      Upgrade
                                    </span>
                                  </p>
                                </button>
                              )} */}
															{/* {user?.role[0] === 'trial' && ( */}
                              {!subscription && (
                                <button 
																	onClick={() => {
																		close()
																		navigate("/payment")
                               		}} 
																	className={'w-full px-5'}>
                                  <p className="w-full py-3  bg-[#17A2B8] my-4 rounded-xl text-white">
                                    <span className="font-medium">
                                      Upgrade
                                    </span>
                                  </p>
                                </button>
                              )}
                            </>
                          )}}
                        </Menu.Item>
												{subscription && (
													<Menu.Item>
														<a
															className="w-full px-5 block text-center"
															href={sessionUrl}
															target="_blank"
														>
															<p className="w-full py-3  bg-[#17A2B8] my-4 rounded-xl text-white">
																<span className="font-medium">
																	Manage subscription
																</span>
															</p>
														</a>
													</Menu.Item>
												)}
                        <Menu.Item>
                          <button
                            className="w-full flex items-center justify-center px-4 py-2 text-sm text-red-500 hover:bg-slate-100"
                            onClick={async() => {
                              logout({ logoutParams: { returnTo: window.location.origin } })
                              // await loginWithRedirect()
                              // navigate("/")
                            }}
                          >
                            <LogoutIcon className="w-4 mr-2" />
                            Sign out
                          </button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 flex flex-col">
                {navigation.map((link) => (
                  <Link
                    key={link.name}
                    to={link.href}
                    className={`${location.pathname === link.href
                      ? "bg-[#17A2B8] text-white"
                      : "text-gray-800 hover:bg-[#2cbad0] hover:text-white"
                      } px-3 py-2 rounded-md text-sm font-medium`}
                  >
                    {link.name}
                  </Link>
                ))}
                {location.pathname !== "/favorites" && (
                  <>
                    {" "}
                    <div className="px-2 block md:hidden">
                      <Search handleSearch={handleSearch} />
                    </div>
                  </>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}

      </Disclosure>
      <UnsubscribeModal viewModal={viewModal} setViewModal={setViewModal} onClick={handleCancelSubscription} />
    </>
  );
}
