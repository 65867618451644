import React from 'react'
import Header from '../../components/header'
import logohorizontal from "../../assets/images/logos/adsteak_h_dark.svg";
import { useState } from 'react';
import PaymentModal from './PaymentModal';
import { useAuth0 } from '@auth0/auth0-react';

function Plans() {
    return (
        <div>
            <section className="bg-white ">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">Designed for business teams like yours</h2>
                        <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p>
                    </div>
                    <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                        {/* Pricing Card */}
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Starter</h3>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best option for personal use &amp; for your next project.</p>
                            <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$29</span>
                                <span className="text-gray-500 dark:text-gray-400">/month</span>
                            </div>
                            {/* List */}
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Individual configuration</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>No setup, or hidden fees</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Team size: <span className="font-semibold">1 developer</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Premium support: <span className="font-semibold">6 months</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Free updates: <span className="font-semibold">6 months</span></span>
                                </li>
                            </ul>
                            <a href="#" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                        </div>
                        {/* Pricing Card */}
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Company</h3>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Relevant for multiple users, extended &amp; premium support.</p>
                            <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$99</span>
                                <span className="text-gray-500 dark:text-gray-400 " >/month</span>
                            </div>
                            {/* List */}
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Individual configuration</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>No setup, or hidden fees</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Team size: <span className="font-semibold">10 developers</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Premium support: <span className="font-semibold">24 months</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Free updates: <span className="font-semibold">24 months</span></span>
                                </li>
                            </ul>
                            <a href="#" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                        </div>
                        {/* Pricing Card */}
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Enterprise</h3>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best for large scale uses and extended redistribution rights.</p>
                            <div className="flex justify-center items-baseline my-8">
                                <span className="mr-2 text-5xl font-extrabold">$499</span>
                                <span className="text-gray-500 dark:text-gray-400">/month</span>
                            </div>
                            {/* List */}
                            <ul role="list" className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Individual configuration</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>No setup, or hidden fees</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Team size: <span className="font-semibold">100+ developers</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Premium support: <span className="font-semibold">36 months</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    {/* Icon */}
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                    <span>Free updates: <span className="font-semibold">36 months</span></span>
                                </li>
                            </ul>
                            <a href="#" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

function Plans_2() {
    const [viewModal, setViewModal] = useState(false)
    const [plan, setPlan] = useState('')
		const {user} = useAuth0();
    return (
        <div>
            <div>
                {/* component */}
                {/* <link rel="preconnect" href="https://fonts.gstatic.com" /> */}
                {/* {/* <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" /> */}
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
                <div className="min-h-screen flex justify-center items-center">
                    <div className>
                        <div className="text-center font-semibold">
                            <h1 className="text-4xl font-bold">
                                <span className="tracking-wide">Find your perfect plan </span>
                                <span>Plans</span>
                            </h1>
                            {/* <p className="pt-6 text-xl text-gray-400 font-normal w-full px-8 md:w-full">
                                Choose a plan that works best for you and<br /> your team.
                            </p> */}
                        </div>
                        <div className="pt-24 flex flex-row">
                            {/* Basic Card */}
                            <div className="w-96 p-8 bg-white text-center rounded-3xl pr-16 shadow-xl">
                                <h1 className="text-black font-semibold text-2xl">Starter</h1>
                                <p className="pt-2 tracking-wide">
                                    <span className="text-gray-400 align-top">$ </span>
                                    <span className="text-3xl font-semibold">49.99</span>
                                    <span className="text-gray-400 font-medium">/ month</span>
                                </p>
                                <hr className="mt-4 border-1" />
                                <div className="pt-8">
                                    <p className="font-semibold text-gray-400 text-left">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">200 ads</span> per query
                                        </span>
                                    </p>
                                    <p className="font-semibold text-gray-400 text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">50 ads analytics</span> per day
                                        </span>
                                    </p>
                                    <p className="font-semibold text-gray-400 text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">50 product analytics</span> per day
                                        </span>
                                    </p>
																		<a
																			href={`https://buy.stripe.com/test_9AQbJB2RKd0H5hK4gj?prefilled_email=${user.email}`}
																		>
																			<p className="w-full py-4 bg-[#17A2B8] mt-8 rounded-xl text-white">
																					<span className="font-medium">
																							Choose Plan
																					</span>
																					<span className="pl-2 material-icons align-middle text-sm">
																							east
																					</span>
																			</p>
																		</a>
                                </div>
                            </div>
                            {/* StartUp Card */}
                            <div className="w-80 p-8 bg-[#17A2B8] text-center rounded-3xl text-white border-4 shadow-xl border-white transform scale-125">
                                <h1 className="text-white font-semibold text-2xl">VIP</h1>
                                <p className="pt-2 tracking-wide">
                                    <span className="text-white align-top">$ </span>
                                    <span className="text-3xl font-semibold">99.99</span>
                                    <span className="text-white font-medium">/ month</span>
                                </p>
                                <hr className="mt-4 border-1 border-white" />
                                <div className="pt-8">
                                    <p className="font-semibold text-white text-left">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-white">1000 ads </span> per query
                                        </span>
                                    </p>
                                    <p className="font-semibold text-white text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-white">200 ad analytics</span> per day
                                        </span>
                                    </p>
                                    <p className="font-semibold text-white text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2 text-gray-300">
                                            <span className="text-white">200 product analytics</span> per day
                                        </span>
                                    </p>
																		<a
																			href={`https://buy.stripe.com/test_9AQfZRakcgcTfWo5ko?prefilled_email=${user.email}`}
																		>
																			<p className="w-full py-4 bg-white mt-8 rounded-xl text-[#17A2B8]">
																				<span className="font-medium">
																						Choose Plan
																				</span>
																				<span className="pl-2 material-icons align-middle text-sm">
																						east
																				</span>
																			</p>
																		</a>
                                </div>
                                <div className="absolute top-4 right-4">
                                    <p className="bg-[#17A2B8] font-semibold px-4 py-1 rounded-full uppercase text-xs">Popular</p>
                                </div>
                            </div>
                            {/* Enterprise Card */}
                            <div className="w-96 p-8 bg-white text-center rounded-3xl pl-16 shadow-xl">
                                <h1 className="text-black font-semibold text-2xl">PRO</h1>
                                <p className="pt-2 tracking-wide">
                                    <span className="text-gray-400 align-top">$ </span>
                                    <span className="text-3xl font-semibold">149.99</span>
                                    <span className="text-gray-400 font-medium">/ month</span>
                                </p>
                                <hr className="mt-4 border-1" />
                                <div className="pt-8">
                                    <p className="font-semibold text-gray-400 text-left">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">2000 ads</span> per query
                                        </span>
                                    </p>
                                    <p className="font-semibold text-gray-400 text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">600 ad analytics</span> per day
                                        </span>
                                    </p>
                                    <p className="font-semibold text-gray-400 text-left pt-5">
                                        <span className="material-icons align-middle">
                                            done
                                        </span>
                                        <span className="pl-2">
                                            <span className="text-black">600 product analytics</span> per day
                                        </span>
                                    </p>
																		<a
																			href={`https://buy.stripe.com/test_8wM00T9g82m3cKc005?prefilled_email=${user.email}`}
																		>
																			<p className="w-full py-4 bg-[#17A2B8] mt-8 rounded-xl text-white">
																					<span className="font-medium">
																							Choose Plan
																					</span>
																					<span className="pl-2 material-icons align-middle text-sm">
																							east
																					</span>
																			</p>
																		</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PaymentModal viewModal={viewModal} setViewModal={setViewModal} plan={plan} />
        </div>
    )
}


export default function PlansPage() {
    return (
        <>
            {/* <Header logo={logohorizontal} /> */}
            <main className="mt-[-50px] bg-slate-100">
                <section
                    className="overflow-hidden "
                    style={{
                        background:
                            "linear-gradient(180deg, #FFFFFF 11.91%, #F1F5F9 40.16%, #F1F5F9 100%)",
                    }}
                >
                    {/* <Plans /> */}
                    <Plans_2 />
                </section>

            </main>
        </>
    )
}
