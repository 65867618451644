import React from 'react'
import { Link } from 'react-router-dom'
import logohorizontal from "../assets/images/logos/adsteak_h_light.svg";
import logoSteak from "../assets/images/logos/adsteak_v_light.svg";

const importantLinks = [
    { name: "Ads Search", href: "/", current: true, important: true },
    { name: "Ads staff picks", href: "/ads_staff_picks", current: false, important: true },
    { name: "Favorites", href: "/favorites", current: false, important: true },
    { name: "Aliexpress Products", href: "/aliexpress_products", current: false, important: true },
    { name: "Products Research", href: "/products_Research", current: false, important: true },
];

const links = [
	{ name: "Privacy Policy", href: "/privacy_policy", current: false },
	{ name: "Terms Of Service", href: "/terms", current: false },
	{ name: "Contact Us", href: "/contact_us", current: false },
]
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

export default function Footer() {
    return (
        // <footer className="bg-white  shadow dark:bg-white">
        //     <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        //         <div className="sm:flex sm:items-center sm:justify-between">
        //             {/* <a href="https://flowbite.com/" className="flex items-center mb-4 sm:mb-0">
        //                 <img src="https://flowbite.com/docs/images/logo.svg" className="h-8 mr-3" alt="Flowbite Logo" />
        //                 <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span>
        //             </a> */}
        //             <Link to="/" id="logo" className="flex-shrink-0 flex items-center">
        //           <img
        //             className="block md:hidden h-12 w-auto"
        //             src={logoSteak}
        //             alt="Workflow"
        //           />
        //           <img
        //             className="hidden md:block h-12 w-auto"
        //             src={logohorizontal}
        //             alt="Workflow"
        //           />
        //         </Link>
        //             <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
        //                 <li>
        //                     <a href="#" className="mr-4 hover:underline md:mr-6 ">About</a>
        //                 </li>
        //                 <li>
        //                     <a href="#" className="mr-4 hover:underline md:mr-6">Privacy Policy</a>
        //                 </li>
        //                 <li>
        //                     <a href="#" className="mr-4 hover:underline md:mr-6 ">Licensing</a>
        //                 </li>
        //                 <li>
        //                     <a href="#" className="hover:underline">Contact</a>
        //                 </li>
        //             </ul>
        //         </div>
        //         <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        //         <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://flowbite.com/" className="hover:underline">Flowbite™</a>. All Rights Reserved.</span>
        //     </div>
        // </footer>
        <section className="bg-white shadow-2xl mt-10">
            <div className="max-w-screen-xl flex justify-evenly gap-3 items-center px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
							<div className='text-gray-600 flex items-center gap-2 flex-col'>
								<div className="flex pl-3 mt-8 space-x-6 mb-4">
									<Link to="/" id="logo" className="flex-shrink-0 flex items-center" onClick={scrollToTop}>
											<img
													className="block md:hidden h-12 w-auto"
													src={logoSteak}
													alt="Workflow"
											/>
											<img
													className="hidden md:block h-12 w-auto"
													src={logohorizontal}
													alt="Workflow"
											/>
									</Link>
								</div>
								<div className='flex flex-col gap-2.5'>
									<span>7 Coronation Road</span>
									<span>London</span>
									<span>NW10 7PQ</span>
									<span>United Kingdom</span>
								</div>
								<p className="mt-8 text-base leading-6 max-w-[200px] text-center text-gray-600">
									<div>
										© 2023 ADSTEAK, Inc. 
									</div>
									<div>
										All rights reserved.
									</div>
								</p>
							</div>
							<nav className="flex items-start gap-2.5 justify-center -mx-5 -my-2 [@media(max-width:561px)]:flex-col">
								<div className='flex flex-col items-start gap-2'>
									{importantLinks.map((navigate) => (
										<div>
											<div className="px-4 py-1">
												{navigate.important ? (
													<Link to={navigate.href} className="text-base leading-6 text-gray-600 hover:text-gray-900" onClick={scrollToTop}>
														{navigate.name}
													</Link>
												) : null}
											</div>
										</div>
									))}
								</div>
								<div className='flex flex-col items-start gap-2'>
									{links.map((navigate) => (
										<div>
											<div className="px-4 py-1">
												{!navigate.important ? (
													<Link to={navigate.href} className="text-base leading-6 text-gray-600 hover:text-gray-900" onClick={scrollToTop}>
														{navigate.name}
													</Link>
												): null}
											</div>
										</div>
									))}
								</div>
							</nav>
            </div>
        </section>
    )
}
