import { Fragment, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
// import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { MdDone, MdSearch, MdExpandMore } from 'react-icons/md';
import { useLocation } from 'react-router-dom';



export default function Searchbar({ handleSearch }) {
  const [query, setQuery] = useState('')
  const location = useLocation();
  const routes = location.pathname === '/products_Research' || location.pathname === '/aliexpress_products' ? [
    { id: 1, name: 'Product Title', value: 'product_title' },
  ] : [
    { id: 1, name: 'Ads Title', value: 'ads_title' },
    { id: 2, name: 'Ad Description', value: 'ad_description' },
    { id: 3, name: 'Advertiser', value: 'advertiser' },
    { id: 4, name: 'Landing Page', value: 'landing_page' },
  ]
  const [selected, setSelected] = useState(routes[0])
  const filteredroutes =
    query === ''
      ? routes
      : routes.filter((person) =>
        person.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )
  // useEffect(() => {
  //   console.log(selected)
  //   return () => {

  //   };
  // }, [selected]);
  return (
    <form onSubmit={(e) => handleSearch(e, selected.value)}>
      <div className="flex  justify-center">
        <div className="z-10" >
          <Combobox value={selected} onChange={setSelected} >
            <div className="relative " >
              <div className="relative w-40  cursor-default overflow-hidden bg-white text-left border rounded-l-md
            focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 
           focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Combobox.Input
                  className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                  displayValue={(person) => person.name}
                  style={{ pointerEvents: 'none' }}
                  onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  {/* <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              /> */}
                  <MdExpandMore />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredroutes.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    filteredroutes.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-[#17A2B8] text-white' : 'text-gray-900'
                          }`
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                }`}
                            >
                              {person.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                  }`}
                              >
                                <MdDone />
                                {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
        <div className="relative w-3/6 z-30" >
          <MdSearch size={20} style={{ position: 'absolute', left: 10, top: '50%', bottom: '50%', transform: 'translate(0,-50%)' }} />

          <label htmlFor="search" className="sr-only">
            please enter any ad keyword...
          </label>
          <input
            type="search"
            id="search"
            name="search"
            className="block p-2  w-full bg-white z-20 text-sm text-gray-900  rounded-r-lg
               border border-[#e5e7eb] focus:ring-[#17A2B8] focus:border-[#17A2B8] pl-10"
            placeholder="please enter any ad keyword..." required />

          <button type="submit" className="absolute top-0 right-0 py-2 px-4 text-sm font-medium text-white
           bg-[#14889a] rounded-r-md border border-[#17A2B8] hover:bg-[#12636f] focus:ring-1
           focus:outline-none focus:ring-[#1fc0d9] dark:bg-[#17A2B8] dark:hover:bg-[#14889a]
            dark:focus:ring-[#12636f]">Search</button>
        </div>
      </div>
    </form>
  )
}