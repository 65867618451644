import React, { useState, useEffect, Fragment } from "react";
import {
  CursorClickIcon,
  FlagIcon,
  PaperClipIcon,
  RefreshIcon,
  TranslateIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import {
  // FilterSelect,
  FilterMultiSelect,
} from "./filter-select";
import MinReactions from "./min-reactions";
import Calander from "./Calander";
import { Menu, Transition } from "@headlessui/react";
import Searchbar from "./Searchbar";
import Facebook from '../assets/icons/facebook.svg'
import Instagram from '../assets/icons/instagram.svg'
import Tiktok from '../assets/icons/tiktok.svg'
import Twitter from '../assets/icons/twitter.svg'
import Youtube from '../assets/icons/youtube.svg'
import Pinterest from '../assets/icons/pinterest.svg'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Filter({
  handleFilter,
  resetFilter,
  reset,
  handleCalander,
  handleCalanderSeenBetween,
  setSelected,
  data,
  clear,
  setclear,
  handleSearch,
  social,
  handleSocial
}) {
  const location = useLocation();
  const [Category, setCategory] = useState([
    { label: "Bags Shoes", value: "Bags Shoes" },
    { label: "Jewelry Watches", value: "Jewelry Watches" },
    { label: "Vehicles", value: "Vehicles" },
    { label: "Fashion", value: "Fashion" },
    { label: "Beauty", value: "Beauty" },
    { label: "Events", value: "Events" },
    { label: "Phone Electronics", value: "Phone Electronics" },
    { label: "Business Finance", value: "Business Finance" },
    { label: "Home Garden", value: "Home Garden" },
    { label: "Health Fitness", value: "Health Fitness" },
  ]);
  useEffect(() => {
    if (location.pathname === "/admin") {
      setCategory((prev) => [
        ...prev,
        { label: "uncategory", value: "uncategory" },
      ]);
    }
  }, []);
  return (
    <div className=" max-w-8xl rounded-lg  py-2 sm:px-6  mx-auto ">
      <Searchbar handleSearch={handleSearch} />
      {/* <div className="w-full my-4 h-[1px] bg-gray-200" /> */}
      <div className="relative flex justify-center items-center my-2 w-full" >
        <button className={`flex flex-row items-center mr-12 py-1 px-2 border-b-2  ${social === 'facebook' ? 'bg-[#EEF6F7]  border-b-[#17A2B8] text-[#17A2B8] ' :'border-b-white'} hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]`} onClick={() => handleSocial("facebook")}>
          <img src={Facebook} width={18} alt="Facebook" />
          <h4 className={`ml-3`}>Facebook</h4>
        </button>
        <button className={`flex flex-row items-center mr-12 py-1 px-2 border-b-2  ${social === 'facebook_library' ? 'bg-[#EEF6F7]  border-b-[#17A2B8] text-[#17A2B8] ' :'border-b-white'} hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]`} onClick={() => handleSocial("facebook_library")}>
          <img src={Facebook} width={18} alt="Facebook" />
          <h4 className={`ml-3`}>Facebook Library</h4>
        </button>
        <button className={`flex flex-row items-center  mr-12  py-1 px-2 border-b-2  ${social === 'instagram' ? 'bg-[#EEF6F7]  border-b-[#17A2B8] text-[#17A2B8]  ' :'border-b-white'} hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]`}  mr-12 onClick={() => handleSocial("instagram")}>
          <img src={Instagram} width={18} alt="Instagram" />
          <h3 className={`ml-3`}>Instagram</h3>
        </button>

        {/* <button className="flex flex-row items-center">
            <img src={Youtube} width={18} alt="Youtube" />
            <h3 className={`ml-3 ${social === 'facebook' && 'text-[#17A2B8]'}`}>Youtube</h3>
          </button> */}
        {/* <button className="flex flex-row items-center">
            <img src={Twitter} width={18} alt="Twitter" />
            <h3 className={`ml-3 ${social === 'facebook' && 'text-[#17A2B8]'}`}>Twitter</h3>
          </button> */}
        <button className={`flex flex-row items-center  mr-12 py-1 px-2 border-b-2  ${social === 'pinterest' ? 'bg-[#EEF6F7]  border-b-[#17A2B8] text-[#17A2B8] ' :'border-b-white'} hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]`}  mr-12 onClick={() => handleSocial("pinterest")}>
          <img src={Pinterest} width={18} alt="Pinterest" />
          <h3 className={`ml-3`}>Pinterest</h3>
        </button>
        <button className={`flex flex-row items-center  mr-12 py-1 px-2 border-b-2  ${social === 'tiktok' ? 'bg-[#EEF6F7]  border-b-[#17A2B8] text-[#17A2B8]':'border-b-white'} hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]`}  mr-12 onClick={() => handleSocial("tiktok")}>
          <img src={Tiktok}  style={{height:20}} alt="Tiktok" />
          <h3 className={`ml-3`}>Tiktok</h3>
        </button>
        <h4 className="text-xl font-medium text-zinc-900"></h4>
       <div className="absolute right-0 bottom-0">
       {/* <button
          className="flex items-center text-gray-600"
          onClick={(e) => {
            e.preventDefault();
            resetFilter();
          }}
        >
          <RefreshIcon className="w-5 mr-2" />
          Reset Filter
        </button> */}
       </div>
      </div>

      <div className="w-full my-2 h-[1px] bg-gray-200" />
      <div className="grid grid-cols-2 gap-x-4 gap-y-2  md:grid-cols-3 lg:grid-cols-5 ">
        <FilterMultiSelect
          label={
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
              Site Type
            </>
          }
          options={[
            { label: "Shopify", value: "Shopify" },
            { label: "WordPress", value: "WordPress" },
            { label: "WooCommerce", value: "WooCommerce" },
            { label: "ClickFunnels", value: "ClickFunnels" },
            { label: "Webflow", value: "Webflow" },
            { label: "BigCommerce", value: "BigCommerce" },
            { label: "Wix", value: "Wix" },
            { label: "PrestaShop", value: "PrestaShop" },
          ]}
          htmlFor="platform"
          setSelected={setSelected}
          name="platform"
          clear={clear}
          setclear={setclear}
          data={data}

        />
        <FilterMultiSelect
          label={
            <>
              <FlagIcon className="w-4 mr-2" />
              Country
            </>
          }
          options={[
            { label: "United States", value: "United States" },
            { label: "South Africa", value: "South Africa" },
            { label: "Germany", value: "Germany" },
            { label: "Ecuador", value: "Ecuador" },
            { label: "Peru", value: "Peru" },
            { label: "Palestine", value: "Palestine" },
            { label: "France", value: "France" },
            { label: "China", value: "China" },
            { label: "Morocco", value: "Morocco" },
            { label: "Colombia", value: "Colombia" },
            { label: "Pakistan", value: "Pakistan" },
            { label: "Canada", value: "Canada" },
            { label: "United Kingdom", value: "United Kingdom" },
            { label: "Ukraine", value: "Ukraine" },
            { label: "Australia", value: "Australia" },
            { label: "Japan", value: "Japan" },
            { label: "Nigeria", value: "Nigeria" },
            { label: "Saudi Arabia", value: "Saudi Arabia" },
            { label: "United Arab Emirates", value: "United Arab Emirates" },
            { label: "Brazil", value: "Brazil" },
            { label: "Hungary", value: "Hungary" },
            { label: "India", value: "India" },
            { label: "Venezuela", value: "Venezuela" },
            { label: "Jordan", value: "Jordan" },
            { label: "South Korea", value: "South Korea" },
            { label: "Honduras", value: "Honduras" },
            { label: "Serbia", value: "Serbia" },
            { label: "Mexico", value: "Mexico" },
          ]}
          setSelected={setSelected}
          htmlFor="location"
          name="country"
          clear={clear}
          setclear={setclear}
          data={data}

        />

        <FilterMultiSelect
          label={
            <>
              <CursorClickIcon className="w-4 mr-2" />
              Button
            </>
          }
          options={[
            { label: "SHOP NOW", value: "SHOP_NOW" },
            { label: "LEARN MORE", value: "LEARN_MORE" },
            { label: "GET OFFER", value: "GET_OFFER" },
            { label: "BOOK TRAVEL", value: "BOOK_TRAVEL" },
            { label: "ORDER NOW", value: "ORDER_NOW" },
            { label: "PLAY GAME", value: "PLAY_GAME" },
            { label: "LISTEN NOW", value: "LISTEN_NOW" },
            { label: "CONTACT US", value: "CONTACT_US" },
            { label: "SUBSCRIBE", value: "SUBSCRIBE" },
            { label: "DOWNLOAD", value: "DOWNLOAD" },
            { label: "APPLY NOW", value: "APPLY_NOW" },
            { label: "DONATE NOW", value: "DONATE_NOW" },
            { label: "WATCH MORE", value: "WATCH_MORE" },
          ]}
          htmlFor="button"
          setSelected={setSelected}
          name="button"
          clear={clear}
          setclear={setclear}
          data={data}

        />
        <FilterMultiSelect
          label={
            <>
              <PaperClipIcon className="w-4 mr-2" />
              Media
            </>
          }
          options={[
            { label: "Video", value: "video" },
            { label: "Image", value: "image" },
          ]}
          setSelected={setSelected}
          htmlFor="media"
          name="ad_format"
          clear={clear}
          setclear={setclear}
          data={data}

        />
        {/* <FilterMultiSelect
          label={
            <>
              <PaperClipIcon className="w-4 mr-2" />
              landing page
            </>
          }
          options={[
            { label: "With Landing Page", value: "with-landing-page" },
            { label: "Without Landing Page", value: "without-landing-page" },
          ]}

          setSelected={setSelected}
          htmlFor="landing page"
          name="landing_page"
          clear={clear}
          setclear={setclear}
          data={data}
          hasSelectAll={false}
          singleSelect={true}
        /> */}
				{social === 'tiktok' && (
					<FilterMultiSelect
						label={
							<>
								<PaperClipIcon className="w-4 mr-2" />
								Sponsored & Trending ads
							</>
						}
						options={[
							{ label: "Sponsored ads", value: "sponsored" },
							{ label: "Trending ads", value: "trending" },
						]}
						setSelected={setSelected}
						htmlFor="tiktok_ads_type"
						name="tiktok_ads_type"
						clear={clear}
						setclear={setclear}
						data={data}
						hasSelectAll={false}
						singleSelect={true}
					/>
				)}
        <FilterMultiSelect
          label={
            <>
              <TranslateIcon className="w-4 mr-2" />
              Language
            </>
          }
          options={[
            { value: "en", label: "English" },
            { value: "es", label: "Spanish" },
            { value: "de", label: "German" },
            { value: "da", label: "Denmark" },
            { value: "ro", label: "Romanian" },
            { value: "et", label: "Estonian" },
            { value: "ar", label: "Arabic" },
            { value: "fr", label: "Franch" },
            { value: "ur", label: "Urdu" },
            { value: "nl", label: "Flemish" },
            { value: "ja", label: "Japanese" },
            { value: "pt", label: "Portuguese" },
            { value: "cs", label: "Czech" },
            { value: "tl", label: "Tagalog" },
          ]}
          setSelected={setSelected}
          htmlFor="Language"
          name="t_lang"
          clear={clear}
          setclear={setclear}
          data={data}

        />

        <FilterMultiSelect
          label={
            <>
              <ViewGridAddIcon className="w-4 mr-2" />
              Category
            </>
          }
          options={Category}
          setSelected={setSelected}
          htmlFor="Category"
          name="Category"
          clear={clear}
          setclear={setclear}
          data={data}

        />

        <MinReactions handleFilter={handleFilter} reset={reset} />
        <Calander title={'Created between'} handleCalander={handleCalander} reset={reset} />
        <Calander title={'Seen between'} handleCalander={handleCalanderSeenBetween} reset={reset} />
        {/* <div className="flex">
          <BiSortDown className="h-6 w-6 md:w-8 md:h-8 m-1 text-sky-600" />
          <select
            className="form-select 
      block
      w-full
      px-2
      py-1.5
      text-sm
      md:text-base
      font-normal
      text-gray-700
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      focus:text-gray-700 focus:bg-white focus:border-orange-600 focus:outline-none"
            name="sort"
            onChange={handleFilter}
            aria-label="Default select example"
            value={reset.sort}
          >
            <option className="text-gray-300">Sort</option>
            <option value="post_create">Post create</option>
            <option value="last_seen">Last seen</option>
            <option value="like">Likes</option>
            <option value="comment">Comments</option>
            <option value="share">Shares</option>
          </select>
        </div> */}
      </div>

      {/* <div className="flex justify-between items-center mt-5 mb-1">
        <h4 className="text-xl font-medium text-zinc-900"></h4>
        <button
          className="flex items-center text-gray-600"
          onClick={(e) => {
            e.preventDefault();
            resetFilter();
          }}
        >
          <RefreshIcon className="w-5 mr-2" />
          Reset Filter
        </button>
      </div> */}
      {/* <div className="lg:col-span-6 col-span-3 flex justify-between ">
        <form
          onSubmit={handleSearch}
          className="relative md:w-3/5 md:ml-8  w-11/12"
        >
          <input
            type="search"
            name="search"
            id="search"
            className="block p-2 pl-10 w-full text-sm md:text-md text-gray-900 rounded-lg border border-gray-300 focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 "
            placeholder="Search Keywords"
            required
          />
          <button
            type="submit"
            className="hover:text-white absolute right-0 bottom-0  hover:bg-sky-600 bg-white text-sky-600 border border-sky-600  rounded-md  m-0 h-full px-6 "
          >
            <BsSearch className="h-6 w-6 md:w-8 md:h-8" />
          </button>
        </form>
        <button
          onClick={resetFilter}
          className="hover:text-white text-sm md:text-md ml-2 sm:ml-4  hover:bg-sky-600 bg-white text-sky-600 border border-sky-600  rounded-md  m-0 h-full px-4 flex items-center "
        >
          Reset <BsArrowClockwise className="h-5 w-5 md:w-6 md:h-6 ml-2" />
        </button>
      </div> */}
      {/* <a href="#filter"> */}

      {/* </a> */}
    </div>
  );
}
