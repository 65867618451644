import React from "react";
import { SearchIcon } from "@heroicons/react/solid";

export default function MinReactions({ handleFilter, }) {

  
  return (
    <form onSubmit={handleFilter} className="flex-col w-full">
      <label className="text-sm font-regular text-gray-700 flex items-center mb-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
          />
        </svg>
        <span className="break-all line-clamp-1"> Reactions minimum</span>
       
      </label>
      <div className="relative flex gap-1">
        <select
          name="reaction"
          id="reaction"
          style={{outline:'none',boxShadow:'none'}}
          className="form-select px-2 w-20 h-[32px]  sm:w-40  text-sm md:text-base font-normal text-gray-700 border border-solid border-[#CBD5D7] rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-[#17A2B8] focus:outline-none"
        >
          <option value="like">Likes</option>
          <option value="comment">Comments</option>
          <option value="share">Shares</option>
        </select>
        <input
          name="min"
          id="min"
          style={{outline:'unset',boxShadow:'unset'}}
          className=" px-2 h-[32px] w-full text-sm md:text-base font-normal  border border-solid border-[#c5cdcf] rounded  focus:text-gray-700 focus:bg-white focus:border-[#17A2B8]  focus:outline-none"
          placeholder="min"
          type="number"
          required
        />

        <button
          type="submit"
          className=" border border-solid h-[32px] border-[#c5cdcf] rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-[#17A2B8] focus:outline-none  px-2  flex items-center"
        >
          <SearchIcon className="h-5 w-4 md:w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
    </form>
  );
}
