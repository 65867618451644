import React, {  useState } from "react";
import API from "../API";
import { HeartIcon } from "@heroicons/react/outline";
import HeartIconSolid from "./heart-icon-solid";
import ToastSavedAd from "./toast-saved-ad";
import { useLocation } from "react-router-dom";
import PopUp from "./Pop-up";
import { useAuth0 } from "@auth0/auth0-react";

export default function AddToFavoriteBtn({ post, myList, setMyList }) {
  const [showToast, setShowToast] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const { user, getAccessTokenSilently } = useAuth0(); 
  const addtoFavorite = async() => {
    try {
      // alert(await getAccessTokenSilently())
      API.post(`favorites`, {
        email: user.email,
        post: post._id,
      }, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }).then(() => {
        setMyList((prev) => [...prev, post._id]);
        setShowToast(true);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const Confirm = async(a) => {
    try {
      API.delete(`favorites?id=${deleteID}` ,{
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      }).then(() => {
        location.pathname === "/favorites"
          && setMyList(myList.filter((item) => item.FavoriteId !== deleteID))
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteFromFavorit = (id) => {
    try {
      setOpen(true);
      setDeleteID(id);
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {
        // post.FavoriteId ? (
        // 	<button onClick={() => deleteFromFavorit(post.FavoriteId)}>
        // 		<HeartIconSolid className='w-6 text-gray-400 hover:text-gray-700' />
        // 	</button>
        // )
        // 	: (

        // isPostSaved
        myList.includes(post._id) || post.FavoriteId
         ? (
          <>
            <button
              onClick={
                post.FavoriteId && (() => deleteFromFavorit(post.FavoriteId))
              }
            >
              <HeartIconSolid className="w-6 text-gray-400 hover:text-gray-700" />
            </button>
            {post.FavoriteId && (
              <PopUp setOpen={setOpen} open={open} setConfirm={Confirm} />
            )}
          </>
        ) : (
          <button onClick={() => addtoFavorite(post._id)}>
            <HeartIcon className="w-6 text-gray-400 hover:text-gray-700" />
          </button>
        )
        // )
      }
      {showToast && <ToastSavedAd setShowToast={setShowToast} />}
    </>
  );
}
