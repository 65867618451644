import React from 'react'

export default function Loading() {
	return (
		<div className="flex items-center justify-center py-16">
			<div className="flex flex-col items-center">
				<div className="lds-ripple"><div></div><div></div></div>
				<p>Loading...</p>
			</div>
		</div>
	)
}
