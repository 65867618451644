/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Fragment, useRef, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import API from "../API";
import {
  FlagIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import { createPortal } from "react-dom";
import PostStats from "./post-stats";
import Loading from "./loading";
import AnalyticsChart from "./analytics-chart";
import AddToFeturedBtn from "./admin/fetured";

import { useAuth0 } from "@auth0/auth0-react";
import PermitionModal from "../pages/payment/PermissionModal";

const Item = ({ icon, label, value, isListTags }) => {
  return (
    <div className="sm:col-span-1">
      <dt className="text-sm font-regular text-gray-700 flex items-center">
        {icon}
        {label}
      </dt>
      {isListTags ? (
        <div className="flex flex-wrap gap-2 mt-2">
          {value.map((item, index) => (
            <span
              key={index}
              className="inline-flex items-center px-2 py-1 rounded text-xs font-medium text-gray-800 border border-gray-300 capitalize"
            >
              {item}
            </span>
          ))}
        </div>
      ) : (
        <dd className="mt-2 text-sm text-gray-900 leading-6">
          {value !== " " ? value : "No desc"}
        </dd>
      )}
    </div>
  );
};

export default function Analytics({
  ID,
  viewAnalytics,
  setViewAnalytics,
  prevPost,
  nextPost,
}) {
  const [post, setPost] = useState();
  const [s3Bucket, setS3Bucket] = useState('');
  const cancelButtonRef = useRef(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const [viewModal, setViewModal] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setPost();
        const updUser ={...user};
        delete updUser['picture']
        API.get(`posts/${ID}?user=${JSON.stringify(updUser)}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        },
        ).then((res) => {

          setPost(res.data[0]);
          setS3Bucket(res.data[0].social_type === undefined ? 'https://aas-bucket.s3.amazonaws.com/uploads' : `https://aas-bucket.s3.amazonaws.com`)
        }).catch((err) => {
          // alert(err?.response?.status )
          if (err.response.status === 429) {
            setViewModal(true)
          }
        })

      } catch (error) {
        console.log(error);

        alert(error?.response?.status)
        // setLoading(false);
      }
    })()
  }, [ID]);

  const AdItemInfo = () => {
    function getUnique(array) {
      var uniqueArray = [];
      for (let i = 0; i < array.length; i++) {
        if (uniqueArray.indexOf(array[i]) === -1) {
          uniqueArray.push(array[i]);
        }
      }
      return uniqueArray;
    }

    return (
      <div className="border-t border-gray-200 py-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
          {/* <p> {JSON.stringify(post)}</p> */}
          {post.category && (
            <Item
              icon={<FlagIcon className="w-4 mr-2" />}
              label="Category:"
              value={JSON.parse(post.category)}
              isListTags={true}
            />
          )}
          {post.interested && (
            <Item
              icon={<FlagIcon className="w-4 mr-2" />}
              label="Targeting Interest:"
              value={post.interested}
            />
          )}
          {post.country_new && (
            <Item
              icon={<FlagIcon className="w-4 mr-2" />}
              label="Target Countries:"
              value={getUnique(post.country_new)}
              isListTags={true}
            />
          )}
          {post.country_seen_new && (
            <Item
              icon={<FlagIcon className="w-4 mr-2" />}
              label="Country Seen in:"
              value={getUnique(post.country_seen_new)}
              isListTags={true}
            />
          )}
          {post.gender && (
            <Item
              icon={<FlagIcon className="w-4 mr-2" />}
              label="Target Gender:"
              value={post.gender}
            />
          )}
          {post.age && (
            <Item
              icon={<FlagIcon className="w-4 mr-2" />}
              label="Target Age:"
              value={post.age}
            />
          )}
          {post.lang && (
            <Item
              icon={<FlagIcon className="w-4 mr-2" />}
              label="Target Language:"
              value={post.lang}
            />
          )}
        </dl>
      </div>
    );
  };
  if (viewModal) {
    return <PermitionModal message="You have reached the maximum number of post analytic for today" viewModal={viewModal} setViewModal={()=>{
      setViewModal(false)
      setViewAnalytics(false)
    }} />
  }
  return createPortal(
    <React.Fragment>
      <Transition.Root show={viewAnalytics} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setViewAnalytics}
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end p-6 sm:items-center justify-center min-h-full text-center h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="w-full h-full relative bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:mx-14 sm:max-w-7xl sm:w-full"
                  style={{ maxHeight: "900px" }}
                >
                  <button
                    type="button"
                    onClick={prevPost}
                    className="absolute text-white top-1/2  left-1 md:-left-16 p-3 rounded-full bg-black bg-opacity-50 z-50 hover:bg-opacity-80"
                    style={{ transform: "translateY(-50%)" }}
                  >
                    <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    onClick={nextPost}
                    className="absolute text-white top-1/2 right-1 md:-right-16 p-3 rounded-full bg-black bg-opacity-50 z-50 hover:bg-opacity-80"
                    style={{ transform: "translateY(-50%)" }}
                  >
                    <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    className="absolute text-white top-5 right-5 p-1 rounded-full bg-black bg-opacity-50 z-50 hover:bg-opacity-80"
                    onClick={() => setViewAnalytics(false)}
                  >
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {post ? (
                    <div className="w-full  h-full rounded-t-lg grid grid-cols-1 gap-y-8 gap-x-6 items-start no-scrollbar overflow-y-scroll sm:grid-cols-12 lg:gap-x-8 ">
                      <div className="col-span-12  border-0  lg:border-r border-gray-200 lg:col-span-5 md:col-span-12 ">
                        <div className="w-full">
                          {`${post.ad_format}`.toLowerCase() === "video" ? (
                            <video
                              className="flex-shrink-0 object-cover h-96 mx-auto"
                              alt=""
                              controls
                              // poster={`https://aas-bucket.s3.amazonaws.com/uploads/${post.resource.image}`}
                              poster={`${s3Bucket + '/' + post.resource.image}`}

                            >
                              <source
                                // src={`https://aas-bucket.s3.amazonaws.com/uploads${post.resource.video}`}
                                src={post.social_type === undefined ? s3Bucket + post.resource.video : s3Bucket + '/' + post.resource.video}
                                type="video/mp4"
                              />
                            </video>
                          ) : (
                            <img
                              className="flex-shrink-0 object-cover h-96 mx-auto bg-slate-200"
                              // src={`https://aas-bucket.s3.amazonaws.com/uploads/${post.resource.image}`}
                              src={`${s3Bucket + '/' + post.resource.image}`}
                              alt=""
                            />
                          )}
                        </div>
                        <div className="w-full py-3 px-5 flex items-center justify-between bg-gray-100">
                          <div>
                            <p className="text-gray-900 text-m font-medium break-all line-clamp-1">
                              {post.title}
                            </p>
                            {/* <p className="mt-1 text-gray-500 text-sm">{ }</p> */}
                          </div>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={post?.landing_page || post?.landanig_page || post?.page_link}
                            className="inline-flex min-w-fit items-center px-4 py-2 border border-gray-300 Rshadow-sm text-sm font-medium rounded-md text-gray-700"
                          >
                            {post?.button?.split("_").join(" ") || "View Page"}
                          </a>
                        </div>

                        <PostStats post={post} fullStats={true} />
                        <div className="w-full flex items-stretch gap-3 p-5 border border-x-0 border-b-0">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={post?.page_link}
                            className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              style={{ width: "20px", marginRight: "8px" }}
                            >
                              <path
                                d="M10.128 13.081V22.375H13.869V13.082H16.989L17.456 9.46H13.869V7.147C13.869 6.099 14.16 5.384 15.664 5.384L17.582 5.383V2.143C17.25 2.099 16.112 2 14.787 2C12.021 2 10.128 3.688 10.128 6.788V9.459H7V13.081H10.128Z"
                                fill="#3C5A9A"
                              />
                            </svg> */}
                            Visit page
                          </a>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={post?.post_link}
                            className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            <ExternalLinkIcon className="w-5 mr-2" />
                            Visit post
                          </a>
                        </div>
                      </div>
                      <div className="w-full col-span-12 p-5 pr-5 h-full  lg:overflow-scroll lg:col-span-7 lg:pl-0 md:px-5 md:col-span-12 md:overflow-visible">
                        <div className="w-full flex items-center pb-5">
                          <img
                            className="w-10 h-10 bg-gray-300 rounded-full border"
                            // src={`https://aas-bucket.s3.amazonaws.com/uploads/${post.resource.page_logo}`}
                            src={`${s3Bucket + '/' + post.resource.page_logo}`}
                            alt=""
                          />
                          <div className="w-full flex items-start justify-between ml-4">
                            <div>
                              <h3 className="text-gray-900 text-m font-medium">
                                {post.page_name}
                                {user?.role?.includes("admin") && <span className="ml-4"> <AddToFeturedBtn post={post} /></span>}

                              </h3>
                              {/* <div className='flex text-gray-500'>
                                <p className="mt-1 text-gray-500 text-sm">20-09-2022</p>
                                <span className='mx-3 font-thin'>|</span>
                                <p className="mt-1 text-gray-500 text-sm">20-09-2022</p>
                              </div> */}

                            </div>

                          </div>
                        </div>
                        <p className="w-full mb-5 text-m text-gray-500 line-clamp-3">
                          {post.Ad_Description
                            ? post.Ad_Description
                            : "No description"}
                        </p>
                        <AdItemInfo />
                        <div className="border-t border-gray-200 py-6 flex flex-col items-center">
                          <AnalyticsChart Data={post} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Loading />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>,
    window.modals
  );
}
