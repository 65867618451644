import React, { useState } from "react";
import { XIcon } from '@heroicons/react/solid'
const lang = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
  { value: "de", label: "German" },
  { value: "da", label: "Denmark" },
  { value: "ro", label: "Romanian" },
  { value: "et", label: "Estonian" },
  { value: "ar", label: "Arabic" },
  { value: "fr", label: "Franch" },
  { value: "ur", label: "Urdu" },
  { value: "nl", label: "Flemish" },
  { value: "ja", label: "Japanese" },
  { value: "pt", label: "Portuguese" },
  { value: "cs", label: "Czech" },
  { value: "tl", label: "Tagalog" },
];
const FilerItem = ({ value, deleteDropdown, title }) => {
  return value.map((element, index) => {
    return (
      <div
        key={index}
        className="flex justify-between  items-center text-[#17A2B8]  rounded-full bg-white py-2 mr-1 pl-4"
      >
        <p style={{ fontSize: '16px', lineHeight: '1' }}>
          {title === "t_lang"
            ? lang.map((el) => {
              if (el.value === element) {
                return el.label;
              }
            })
            : element.split(" ").join("_")}
        </p>

        {/* <button
          title={title}
          // className="px-1 "
          onClick={deleteDropdown}
          name={element}
        >
        </button> */}
        <XIcon className="w-4 ml-1 mr-4" onClick={() => deleteDropdown(title, element)} color='#17A2B8' />
      </div>
    );
  });
};

export default function List({
  data,
  setReset,
  setData,
  setPosts,
  setSelected,
  setskip,
  resetFilter
}) {
  const [newfilter, setnewfilter] = useState(data);

  const deleteFilter = (name) => {
    delete newfilter?.[name];
    setReset((data) => ({ ...data, [name]: "", skip: 0 }));
    setPosts([]);
    setskip(1);
    setnewfilter({ ...newfilter });
    setData({ ...newfilter });
    if (name === "reaction") {
      document.getElementById("min").value = "";
      document.getElementById("reaction").value = "like";
    } else if (name === "search") {
      document.getElementById("search").value = "";
    }
    // e.preventDefault();
  };
  const deleteDropdown = (title, name) => {
    setSelected({
      name: title,
      value: data?.[title].filter((item) => item !== name),
    });
    // setSelected({
    //   name: e.target.title,
    //   value: data?.[e.target.title].filter((item) => item !== e.target.name),
    // });
  };
  return (
    <div
      id="box"
      className="flex overflow-y-auto text-sm py-2  lg: mr-2"
    >
      {(data?.wishlist || data?.votes || data?.category  || data?.search || data?.daily_orders || data?.total_orders_number || data?.price || data?.ships_from?.length > 0 || data?.shipping?.length > 0 || data?.top_counties?.length > 0 || data?.niches?.length > 0 ) && (
        <div className="py-2 min-w-[70px] text-[#93999A]" >
          selected :
        </div>
      )}
      {data?.shipping && (
        <>
          <FilerItem
            value={data.shipping}
            title="shipping"
            deleteDropdown={deleteDropdown}
          />
        </>
      )}
      {data?.ships_from && (
        <>
          <FilerItem
            value={data.ships_from}
            title="ships_from"
            deleteDropdown={deleteDropdown}
          />
        </>
      )}
      {data?.category && (
        <>
          <FilerItem
            value={data.category}
            title="category"
            deleteDropdown={deleteDropdown}
          />
        </>
      )}

      {data?.top_counties && (
        <>
          <FilerItem
            value={data.top_counties}
            title="top_counties"
            deleteDropdown={deleteDropdown}
          />
        </>
      )}
      {data?.niches && (
        <>
          <FilerItem
            value={data.niches}
            title="niches"
            deleteDropdown={deleteDropdown}
          />
        </>
      )}

      {data?.price && (
        <div className=" flex justify-between rounded-full  mr-1 text-[#17A2B8] py-2  pl-4 bg-white   ">
          price: {data.price.from + "_" + data.price.to}
          <XIcon className="w-4 ml-1 mr-4" onClick={() => deleteFilter('price')} color='#17A2B8' />
        </div>
      )}
      {data?.total_orders_number && (
        <div className=" flex justify-between rounded-full  mr-1 text-[#17A2B8] py-2  pl-4 bg-white   ">
          total orders :{data.total_orders_number.from + "_" + data.total_orders_number.to}
          <XIcon className="w-4 ml-1 mr-4" onClick={() => deleteFilter('total_orders_number')} color='#17A2B8' />
        </div>
      )}
      {data?.daily_orders && (
        <div className=" flex justify-between rounded-full  mr-1 text-[#17A2B8] py-2  pl-4 bg-white   ">
          daily orders: {data.daily_orders.from + "_" + data.daily_orders.to}
          <XIcon className="w-4 ml-1 mr-4" onClick={() => deleteFilter('daily_orders')} color='#17A2B8' />
        </div>
      )}
      {data?.votes && (
        <div className=" flex justify-between rounded-full  mr-1 text-[#17A2B8] py-2  pl-4 bg-white   ">
          votes: {data.votes.from + "_" + data.votes.to}
          <XIcon className="w-4 ml-1 mr-4" onClick={() => deleteFilter('votes')} color='#17A2B8' />
        </div>
      )}
      {data?.wishlist && (
        <div className=" flex justify-between rounded-full  mr-1 text-[#17A2B8] py-2  pl-4 bg-white   ">
          wishlist: {data.wishlist.from + "_" + data.wishlist.to}
          <XIcon className="w-4 ml-1 mr-4" onClick={() => deleteFilter('wishlist')} color='#17A2B8' />
        </div>
      )}
      {data?.search && (
        <div className=" flex justify-between rounded-full  mr-1 text-[#17A2B8] py-2  pl-4 bg-white  ">
          {data.search}
          <XIcon className="w-4 ml-1 mr-4" onClick={() => deleteFilter('search')} color='#17A2B8' />
          {/* <button
            className="px-1 font-bold"
            onClick={deleteFilter}
            name="search"
          >
            X
          </button> */}
        </div>
      )}

    
      {(data?.wishlist || data?.votes || data?.category || data?.search || data?.daily_orders || data?.total_orders_number || data?.price || data?.ships_from?.length > 0 || data?.shipping?.length > 0 || data?.top_counties?.length > 0 || data?.niches?.length > 0 ) && (
        <button className="py-2 min-w-[70px] px-2 ml-3 text-[#93999A]" onClick={(e) => {
          e.preventDefault();
          resetFilter();
        }}>
          clear all
        </button>
      )}

    </div>
  );
}
