import React, { useEffect, useState } from 'react';
import API from '../API';
import { useAuth0 } from '@auth0/auth0-react';

export function useStripeInit(){
  const [sessionUrl, setSessionUrl] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const { user } = useAuth0();

  useEffect(() => {
    async function stripeInit(){
      try {
        const res = await API.post(`stripe/create-session`, { 
          data: {
            user_id: user._id,
            email: user.email
          }
        });
        setSessionUrl(res?.data?.session?.url)
        setSubscription(res?.data?.subscription)
        console.log(res, 'res')
      } catch (error) {
        console.log(error, 'session response error')
      }
    }
    stripeInit();
  }, [])
  return [subscription, sessionUrl];
}