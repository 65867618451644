import {
  ChartBarIcon,
  LibraryIcon,
  ExternalLinkIcon,
  PlayIcon,
} from "@heroicons/react/outline";
import { useRef, useState, useEffect, Fragment } from "react";
import Analytics from "./analytics";
import PostStats from "./post-stats";
import AddToFavoriteBtn from "./add-to-favorite-btn";
import { dateFormat, lastseenDateCount } from "../controllers/actions";
import Description from "./Description";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaTiktok, FaPinterest, FaPlay } from "react-icons/fa";
import ReactPlayer from 'react-player'
import AddToStoreBtn from "./AddToStoreBtn";
import AddToDraftBtn from "./add-to-draft-btn";
import { useLocation } from "react-router-dom";
import Facebook from '../assets/icons/post/facebook.svg'
import FacebookFilled from '../assets/icons/post/facebook_filled.svg'
import Instagram from '../assets/icons/post/instagram.svg'
import InstagramFilled from '../assets/icons/post/instagram_filled.svg'
import Tiktok from '../assets/icons/post/tiktok.svg'
import TiktokFilled from '../assets/icons/post/tiktok_filled.svg'
import Pinterst from '../assets/icons/post/pinterst.svg'
import PinterstFilled from '../assets/icons/post/pinterst_filled.svg'
import Android from '../assets/icons/post/android.svg'
import Web from '../assets/icons/post/web.svg'

export default function PostItem({ post, user, myList, setMyList, posts, store = false, onDeleteSuccess, myDraftList, setMyDraftList , onMouseEnter , onMouseLeave ,playing ,index}) {
  const [viewAnalytics, setViewAnalytics] = useState(false);
  // const [playing, setPlaying] = useState(false);
  const [ID, setID] = useState();
  const [socialHover, setSocialHover] = useState(false);
  const location = useLocation()
  const divRef = useRef(null)
  const s3_bucket = post?.social_type !== undefined ? `https://aas-bucket.s3.amazonaws.com` : 'https://aas-bucket.s3.amazonaws.com/uploads'
  const ShowAnalytics = () => {
    setViewAnalytics((prev) => !prev);
    setID(post?._id);
  };
  const SeenextPost = (e) => {
    try {
      e.preventDefault();
      posts.forEach((element, index) => {
        if (element?._id === ID && posts[index + 1]) {
          setID(posts[index + 1]?._id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   divRef?.current?.addEventListener('mouseleave', () => setPlaying(false));
  //   return () => {
  //     divRef?.current?.addEventListener('mouseover', () => setPlaying(false));
  //   };
  // }, [])
  const handlevedioError = (e) => {
    e.target.poster = "";
  };
  const SeeprevPost = (e) => {
    try {
      e.preventDefault();
      posts.forEach((element, index) => {
        if (element?._id === ID && posts[index - 1]) {
          setID(posts[index - 1]?._id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const Header = () => {
    return (
      <div>
        <div className="w-full flex items-center p-3">
				{post?.ads_count ? (
					<div className="min-w-[40px]">
						<img
							className={`w-[35px] object-cover mb-2 bg-gray-300 rounded-full border`}
							// src={`https://aas-bucket.s3.amazonaws.com/uploads/${post?.resource?.page_logo}`}
							src={`${s3_bucket + '/' + post?.resource?.page_logo}`}
							alt=""
						/>
						<div className="text-[11px]">Ads: {post?.ads_count}</div>
					</div>
				) : (
					<img
						className={`w-10 h-10 bg-gray-300 rounded-full border`}
						// src={`https://aas-bucket.s3.amazonaws.com/uploads/${post?.resource?.page_logo}`}
						src={`${s3_bucket + '/' + post?.resource?.page_logo}`}
						alt=""
					/>
				)}
          <div className="w-full relative flex items-start gap-1 justify-between ml-4">
            <div>
              <h3 className="text-gray-900 font-medium break-all line-clamp-1">
                {post?.page_name}
              </h3>
              <div className=" mt-[-2px] pb-1 text-gray-500">
                <div className="flex flex-row items-center">
                  <p className=" text-gray-500 text-sm mr-2">
                    {/* Last seen: {new Date(post?.last_seen).toLocaleDateString().replaceAll("/","-")} */}
                    Last seen: {dateFormat(post?.last_seen)}
                  </p>
                  {post?.social_type === 'instagram' ? (
                    <div className="flex ml-1 items-center " onMouseEnter={() => { setSocialHover(true) }} onMouseLeave={() => setSocialHover(false)}>
                      <a
                        href={post?.post_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ height: 26 }}
                      >
                        {socialHover ? <img src={InstagramFilled} width={26} /> :
                          <img src={Instagram} width={26} />}
                      </a>
                    </div>
                  ) : post?.social_type === 'tiktok' ? (
                    <div className="flex ml-1 items-center " onMouseEnter={() => { setSocialHover(true) }} onMouseLeave={() => setSocialHover(false)}>
                      <a
                        href={post?.post_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ height: 26 }}
                      >
                        {socialHover ? <img src={TiktokFilled} width={26} /> :
                          <img src={Tiktok} width={26} />}
                      </a>
                    </div>
                  ) : post?.social_type === 'pinterest' ? (
                    <div className="flex ml-1 items-center " onMouseEnter={() => { setSocialHover(true) }} onMouseLeave={() => setSocialHover(false)}>
                      <a
                        href={post?.post_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ height: 26 }}
                      >
                        {socialHover ? <img src={PinterstFilled} width={26} /> :
                          <img src={Pinterst} width={26} />}
                      </a>
                    </div>
                  ) : (
                    <div className="flex ml-1 items-center " onMouseEnter={() => { setSocialHover(true) }} onMouseLeave={() => setSocialHover(false)}>
                      <a
                        href={post?.post_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ height: 26 }}
                      >
                        {socialHover ? <img src={FacebookFilled} width={26} /> :
                          <img src={Facebook} width={26} />}

                      </a>
                    </div>
                  )}

                </div>
                <p className="absolute w-100 text-[10px] flex ">
                  Created {lastseenDateCount(post?.post_create)} days ago
                  {/* Created {post?.post_create}  */}
                  {/* <a
                    href={post?.post_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ExternalLinkIcon className="w-[14px] ml-1" />
                  </a> */}
                </p>
              </div>
            </div>
            <div className="absolute right-2 bottom-[-10px] flex gap-1">
              {post?.system == 'android' ?  <img src={Android} width={20} /> :  <img src={Web} width={20} />}
             

              {/* <img src={Facebook} width={26} /> */}

            </div>
            {user?.role?.includes("admin") && (
              <AddToDraftBtn
                post={post}
                myDraftList={myDraftList}
                setMyDraftList={setMyDraftList}
              />
            )}
            {location.pathname !== "/draft" && (
              <AddToFavoriteBtn
                post={post}
                myList={myList}
                setMyList={setMyList}
              />
            )}

            {user?.role?.includes("admin") && (
              <AddToStoreBtn
                post={post}
                product_model={'posts'}
                store={store}
                onDeleteSuccess={onDeleteSuccess}
              />
            )}

          </div>
        </div>
      </div>
    );
  };

  const Actions = () => {
    return (
      <div className="w-full flex flex-col items-center gap-2 p-3 border border-x-0 border-b-0">
        <button
          type="button"
          className="w-full flex items-center justify-center 
          px-4 py-2  shadow-sm text-sm font-medium rounded-md 
          bg-[#17A2B8] hover:bg-[#118a9c] text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#17A2B8]"
          onClick={ShowAnalytics}
        >
          <ChartBarIcon className="w-5 mr-2" />
          View Analytics
        </button>
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            "https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=" +
            post?.page_id +
            "&search_type=page&media_type=all"
          }
          className="flex items-center text-gray-700 mt-2 text-sm font-medium"
        >
          <LibraryIcon className="w-5 mr-2" />
          View Ads Library
        </a> */}
      </div>
    );
  };

  return (
    <>
      <Header />

      <Description Description={post?.Ad_Description} id={post?._id} />
      <div className="w-full" 
      // onMouseLeave={() => setPlaying(false)}
      >

        {post?.resource?.video ? (
          <div style={{
            backgroundImage: `url("${s3_bucket + '/' + post?.resource?.image}")`
          }}
            ref={divRef}
            className={`relative cursor-pointer flex justify-center bg-center bg-cover bg-no-repeat`}
            //  onMouseEnter={() => setPlaying(true)} onMouseLeave={() => setPlaying(false)}
             onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
          // style={{backgroundImage:`url(https://adsteak.s3.us-east-2.amazonaws.com/${post?.resource?.image})`}}
          >
            {/* <video
              className="flex-shrink-0 object-contain h-60 mx-auto"
              alt=""
              controls
              // poster={`https://aas-bucket.s3.amazonaws.com/uploads/${post?.resource?.image}`}
              poster={`https://adsteak.s3.us-east-2.amazonaws.com/${post?.resource?.image}`}
              onError={handlevedioError}
            >

              <source
                // src={`https://aas-bucket.s3.amazonaws.com/uploads${post?.resource?.video}`}
                src={`https://adsteak.s3.us-east-2.amazonaws.com/${post?.resource?.video}`}
                type="video/mp4"
              />

            </video> */}
            <div style={{
              //  background: 'rgba(255, 255, 255, 0.2)', // Make sure this color has an opacity of less than 1
              backdropFilter: 'blur(4px)', width: '100%', height: '100%'
            }} className={'relative cursor-pointer flex justify-center '}>
              <ReactPlayer
                url={post?.social_type !== undefined ? `${s3_bucket + '/' + post?.resource?.video}` : s3_bucket + post?.resource?.video}
                width={'auto'}
                height={208}

                // light
                // light={playing === true ? false : (
                light={playing === index ? false : (
                  <div >
                    <div
                      style={{
                        backgroundImage: `url("${s3_bucket + '/' + post?.resource?.image}")`,
                      }}
                      className={`absolute w-full h-full top-0 left-0  bg-[url('https://www.w3schools.com/howto/photographer.jpg')] blur-[4px] bg-center bg-cover bg-no-repeat`} />
                    <img style={{
                      height: 208, top: ' 50%',
                      left: ' 50%',
                      transform: 'translate(-50%, -50%)',
                      position: 'absolute',
                    }} src={`${s3_bucket + '/' + post?.resource?.image}`} alt='Thumbnail' />
                  </div>
                )}
                playIcon={
                  <div className={`absolute  w-full h-full flex items-center justify-center `}>
                    <FaPlay size={55} color={'white'} className={'outline outline-[5px] rounded-md b p-4'} />
                  </div>}
                playing={playing === index}
                // onClickPreview={()=>{alert('jksdfn')}}
                controls
              />
            </div>


            {/* <button className="absolute  backdrop-contrast-150  top-0  w-full h-full flex items-center justify-center"
            onClick={()=>{
          
            }}>
              <PlayIcon width={80} color={'white'} className={''} />
            </button> */}
          </div>
        ) : (
          <img
            className="flex-shrink-0 object-contain h-[208px] mx-auto bg-slate-200"
            // src={`https://aas-bucket.s3.amazonaws.com/uploads/${post?.resource?.image}`}
            src={`${s3_bucket + '/' + post?.resource?.image}`}
            style={{ objectFit: 'cover', }}
            alt=""
          />

        )}
      </div>
      <div className="w-full h-[50px] px-3 flex gap-2 items-center justify-between bg-gray-100">
        <div >
          <p className="text-[#3C4344]   text-[14px] font-medium line-clamp-1 break-all">
            {post?.title}
          </p>
          {/* <p className="mt-1 text-gray-500 text-sm">{ }</p> */}
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={post?.landing_page || post?.landanig_page || post?.page_link}
          className="inline-flex min-w-fit items-center px-3 py-[5px] border-[1.5px] border-[#17A2B8]  Rshadow-sm text-sm rounded-[8px] text-[#17A2B8] hover:text-white hover:bg-[#17A2B8]"
        >
          {post?.button?.split("_").join(" ") || "View Page"}
        </a>
      </div>
      {post?.social_type !== 'facebook_library' && (
        <PostStats post={post} />
      )}
      <Actions />
      {viewAnalytics && (
        <Analytics
          viewAnalytics={viewAnalytics}
          setViewAnalytics={setViewAnalytics}
          ID={ID}
          prevPost={SeeprevPost}
          nextPost={SeenextPost}
        />
      )}
    </>
  );
}
