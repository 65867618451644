import React, { useEffect, useState } from "react";
import API from "../API";
import { useAuth0 } from "@auth0/auth0-react";
import PostItem from "./postItem";
import { HeartIcon } from "@heroicons/react/solid";
import Loading from "./loading";
import Facebook from '../assets/icons/facebook.svg'
import Instagram from '../assets/icons/instagram.svg'
import Tiktok from '../assets/icons/tiktok.svg'
import Twitter from '../assets/icons/twitter.svg'
import Youtube from '../assets/icons/youtube.svg'
import Pinterest from '../assets/icons/pinterest.svg'

export default function Favorites() {
  const [myList, setMyList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user, getAccessTokenSilently } = useAuth0();
  const [social, setSocial] = useState('facebook')
  const [playing, setPlaying] = useState(-1);
  useEffect(() => {
    (async () => {
      try {
        setMyList([]);
        API.get(`favorites?email=${user.email}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }).then((res) => {
          if (res.data) {
            res.data.forEach((element) => {
              element.post["FavoriteId"] = element._id;
              setMyList((prev) => [...prev, element.post]);
            });
          }
          setIsLoading(false);
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const handleSocial = (name) => {
    if (social !== name) {
      setSocial(name)
    }
  };
  return (
    <main
      className="mt-10 min-h-[360px]" onMouseEnter={() => setPlaying(-1)}
    >
      <div className="mt-24 max-w-8xl mx-auto  px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-semibold text-[#17A2B8] ">Favorites</h1>
        <div className="relative flex justify-center items-center my-2 w-full mb-10 mt-4" >
          <button className={`flex flex-row items-center mr-12 py-1 px-2 border-b-2 rounded-md ${social === 'facebook' ? 'bg-[#EEF6F7]  border-b-[#17A2B8] text-[#17A2B8] ' : 'border-b-white'} hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]`} onClick={() => handleSocial("facebook")}>
            <img src={Facebook} width={18} alt="Facebook" />
            <h4 className={`ml-3`}>Facebook</h4>
          </button>
          <button className={`flex flex-row items-center mr-12 py-1 px-2 border-b-2 rounded-md  ${social === 'facebook_library' ? 'bg-[#EEF6F7]  border-b-[#17A2B8] text-[#17A2B8] ' : 'border-b-white'} hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]`} onClick={() => handleSocial("facebook_library")}>
            <img src={Facebook} width={18} alt="Facebook" />
            <h4 className={`ml-3`}>Facebook Library</h4>
          </button>
          <button className={`flex flex-row items-center  mr-12  py-1 px-2 border-b-2 rounded-md  ${social === 'instagram' ? 'bg-[#EEF6F7]  border-b-[#17A2B8] text-[#17A2B8]  ' : 'border-b-white'} hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]`} mr-12 onClick={() => handleSocial("instagram")}>
            <img src={Instagram} width={18} alt="Instagram" />
            <h3 className={`ml-3`}>Instagram</h3>
          </button>
          <button className={`flex flex-row items-center  mr-12 py-1 px-2 border-b-2 rounded-md  ${social === 'pinterest' ? 'bg-[#EEF6F7]  border-b-[#17A2B8] text-[#17A2B8] ' : 'border-b-white'} hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]`} mr-12 onClick={() => handleSocial("pinterest")}>
            <img src={Pinterest} width={18} alt="Pinterest" />
            <h3 className={`ml-3`}>Pinterest</h3>
          </button>
          <button className={`flex flex-row items-center  mr-12 py-1 px-2 border-b-2 rounded-md  ${social === 'tiktok' ? 'bg-[#EEF6F7]  border-b-[#17A2B8] text-[#17A2B8]' : 'border-b-white'} hover:text-[#17A2B8] hover:bg-[#EEF6F7] hover:border-b-2 hover:border-b-[#17A2B8]`} mr-12 onClick={() => handleSocial("tiktok")}>
            <img src={Tiktok} style={{ height: 20 }} alt="Tiktok" />
            <h3 className={`ml-3`}>Tiktok</h3>
          </button>
          <h4 className="text-xl font-medium text-zinc-900"></h4>
          <div className="absolute right-0 bottom-0">
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : myList?.length !== 0 ? (
          <ul
            id="header"
            className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 " onMouseEnter={() => setPlaying(-1)}
          >
            {myList?.filter(post => {
              if (social === 'facebook') {
                return (post?.social_type === social || !post?.social_type)
              } else {
                return post?.social_type === social
              }
            }).map((post,index) => (
              <li
                key={post._id}
                className="col-span-1 bg-white rounded-lg shadow w-full"
              >
                <PostItem
                  post={post}
                  user={user}
                  myList={myList}
                  setMyList={setMyList}
                  posts={myList}
                  onMouseEnter={() => setPlaying(index)}
                  onMouseLeave={() => setPlaying(-1)}
                  playing={playing}
                  index={index}
                />
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center h-full flex flex-col justify-center items-center min-h-[250px]">
            <div className="mb-3 p-3 bg-slate-200 rounded-full">
              <HeartIcon className="w-6 text-slate-100" />
            </div>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No result found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Try save post by clicking on the heart
            </p>
          </div>
        )}
      </div>
    </main>
  );
}
