import React, { useEffect, useState, useRef } from "react";
import API from "../API";
import Filter from "./Filter";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowSmUpIcon,
  SearchIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";
import PostItem from "./postItem";
import Loading from "./loading";
import { FilterSelect } from "./filter-select";
import List from "./list";
import PaginatedItems from "./PaginatedItems";
import Pagination from "./Pagination";
import PaginationNav1 from "./PaginatedItems";
import PermitionModal from "../pages/payment/PermissionModal";
// import { useSearchParams } from "react-router-dom";
export default function Home({ fetured }) {
  const [posts, setPosts] = useState([]);
  const didMount = useRef(false);
  const [loading, setLoading] = useState(false);
  const [noResult, setnoResult] = useState(false);
  const [skip, setskip] = useState(0);
  const [social, setSocial] = useState('facebook')
  const [data, setData] = useState({
    skip: 0,
    sort: "last_seen",
    fetured: fetured,
    social_type: social
  });
  const [reset, setReset] = useState({});
  const { user, getAccessTokenSilently } = useAuth0();
  const [myList, setMyList] = useState([]);
  const [myDraftList, setMyDraftList] = useState([]);
  const [selected, setSelected] = useState();
  const [clear, setclear] = useState(false);
  const [Search, setSearch] = useState([""]);
  const [searchType, setSearchType] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [countPages, setCountPages] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [playing, setPlaying] = useState(-1);
  // const pageItems = 100
  const pageItems = user?.role?.includes('trial') ? 12 : 100
  // const [searchParams, setSearchParams] = useSearchParams();
  const box = document.getElementById("box");

  // console.log(JSON.parse(searchParams.get("filter")));

  const handleFilter = (event) => {
    try {
      setPosts([]);
      setPageIndex(0)
      setCountPages(0)
      setnoResult(false);
      let name = event.target.name;
      let value = event.target.value;
      if (event.target.reaction) {
        name = "reaction";
        value = {
          name: event.target?.reaction.value,
          min: Number(event.target?.min.value) || 0,
        };
      }
      setData({ ...data, [name]: value, skip: 0 });
      setReset({ ...reset, [name]: value });
      setskip(0);
      event.preventDefault();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalander = (start, end) => {
    try {
      setPosts([]);
      setPageIndex(0)
      setCountPages(0)
      setnoResult(false);
      setData((data) => ({
        ...data,
        calander: {
          start: start,
          end: end,
        },
        skip: 0,
      }));
      setskip(0);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCalanderSeenBetween = (start, end) => {
    try {
      setPosts([]);
      setPageIndex(0)
      setCountPages(0)
      setnoResult(false);
      setData((data) => ({
        ...data,
        "seen_between": {
          start: start,
          end: end,
        },
        skip: 0,
      }));
      setskip(0);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selected?.name) {
      setPosts([]);
      setPageIndex(0)
      setCountPages(0)
      setnoResult(false);
      setData((data) => ({
        ...data,
        [selected?.name]: selected?.value,
        skip: 0,
      }));
      setskip(0);
    }
  }, [selected]);

  const resetFilter = () => {
    try {
      setPosts([]);
      setclear(true);
      setskip(0);
      setPageIndex(0)
      setCountPages(0)
      setData({ skip: 0, sort: "last_seen", fetured: fetured, social_type: social });
      setReset({
        sort: "last_seen",
      });
      document.getElementById("search").value = "";
      document.getElementById("min").value = "";
      document.getElementById("reaction").value = "like";
    } catch (error) {
      console.log(error);
    }
  };
  document.getElementById("logo")?.addEventListener("click", resetFilter);
  useEffect(() => {
    if (Search) {
      setPosts([]);
      setPageIndex(0)
      setCountPages(0)
      setnoResult(false);
      setData((data) => ({ ...data, search: Search, search_type: searchType, skip: 0 }));
      setskip(0);
    }
  }, [Search, searchType]);

  const handleScroll = (event) => {
    const el = event.target.documentElement;
    if (
      el.scrollHeight - el.scrollTop < el.scrollHeight / 2 &&
      !loading &&
      !noResult
    ) {
      setLoading(true);
      setskip(skip + 1);
    }
  };
  // window.addEventListener("scroll", handleScroll);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    resetFilter();
  }, [fetured]);

  // useEffect(() => {
  //   if (skip !== 0) {
  //     setData((data) => ({ ...data, skip: skip }));
  //   }
  // }, [skip]);

  // useEffect(() => {
  //   setPosts((data) =>
  //     data.filter(
  //       (item) =>
  //         new Date().getTime() - new Date(item.created_at).getTime() > 90000
  //     )
  //   );
  // }, [loading]);

  useEffect(() => {
    (async () => {
      // console.log("first");
      // alert("first");
      try {
        setLoading(true);

        console.log(`filter=${JSON.stringify(data)}`)
        // setSearchParams(`filter=${JSON.stringify(data)}`);
        const updUser ={...user};
        delete updUser['picture']
        const res = await API.get(`posts?user=${JSON.stringify(updUser)}&data=${JSON.stringify(data)}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        });
        // setPosts([])
        setPosts([...res?.data?.post]);
        setPageIndex(data.skip)
        setCountPages(Math.round(Number(res?.data?.countDocuments) / pageItems))

        setLoading(false);
        if (res.data?.length === 0) {
          setnoResult(true);
        }
      } catch (error) {
        // alert(JSON.stringify(error.response.data))
        if (error?.response?.status === 429) {
          setViewModal(true)
        }
        setLoading(false);
        // alert(JSON.stringify(error.response.status))
        // console.log(error);
      }
    })();
  }, [data]);
  // useEffect(()=>{
  //   setPageIndex(0)
  //       setCountPages(0)
  // },[data])

  useEffect(() => {
    (async () => {
      try {
        // let filter = JSON.parse(searchParams.get("filter")) || {}
        // if (Object.keys(filter).length > 3) {
        //   console.log(Object.keys(filter).length)
        //   // setPosts([])
        //   // setData(filter)
        // }

        // console.log(filter);
        API.get(`favorites?email=${user.email}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }).then((res) => {
          setMyList([]);
          res.data.forEach((element) => {
            setMyList((prev) => [...prev, element.post?._id]);
          });
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        API.get(`draft?email=${user.email}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }).then((res) => {
          setMyDraftList([]);
          res.data.forEach((element) => {
            setMyDraftList((prev) => [...prev, { _id: element?.post?._id, isDeleted: element?.isDeleted, isExported: element?.isExported }]);
          });
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const handleSearch = (e, searchType) => {
    try {
      e.preventDefault();
      if (searchType === 'landing_page') {
        setSearchType(searchType)
        setSearch([e.target.search.value]);
      } else {
        let search = e.target.search.value
          .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")
        setSearchType(searchType)
        setSearch([search]);
      }

    } catch (error) {
      console.log(error);
    }
  };
  const handleSocial = (name) => {
    if (social !== name) {
      setPosts([])
      setPageIndex(0)
      setCountPages(0)
      setSocial(name)
      setnoResult(false);
      setData((prev) => ({ ...prev, skip: 0, social_type: name }))
      setskip(0);
    }
  };
  return (
    <main
      className="mt-5 relative"
      style={{ height: "calc(100% - 112px)" }} onMouseEnter={() => setPlaying(-1)}
    >
      <div className="h-full w-full  bg-white px-4 sm:px-6 lg:px-10">
        <Filter
          handleFilter={handleFilter}
          resetFilter={resetFilter}
          reset={reset}
          setSelected={setSelected}
          handleCalander={handleCalander}
          handleCalanderSeenBetween={handleCalanderSeenBetween}
          data={data}
          setclear={setclear}
          clear={clear}
          handleSearch={handleSearch}
          social={social}
          handleSocial={handleSocial}
        />
      </div>

      <div className="h-full flex flex-col max-w-8xl mx-auto px-4 sm:px-6 lg:px-10" >
        {posts?.length <= 0 && !loading ? (
          <div className="mt-3">
            <List
              data={data}
              setReset={setReset}
              setData={setData}
              setPosts={setPosts}
              setSelected={setSelected}
              setskip={setskip}
              resetFilter={resetFilter}
            />
            <div className="text-center h-full flex flex-col mt-3  justify-center items-center min-h-[250px]">
              <div className="mb-3 p-3 bg-slate-200 rounded-full">
                <SearchIcon className="w-6 text-gray-400" />
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900 ">
                No result found
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Try diffirent keyword
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between mt-2 mb-2">
              {box.childNodes?.length === 0 && (
                <h2 className="text-2xl font-medium text-zinc-900">
                  Items list {social.replace('_', ' ')} ads:
                </h2>
              )}
              <List
                data={data}
                setReset={setReset}
                setData={setData}
                setPosts={setPosts}
                setSelected={setSelected}
                setskip={setskip}
                resetFilter={resetFilter}
              />
              <div className="flex   items-center ">
                <div className="mr-2 flex items-center w-full text-gray-600">
                  {/* <button className="form-select flex-col mr-2 p-2  text-base font-normal text-gray-700 border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 bg-white focus:border-orange-600 focus:outline-none"> */}
                  <SortDescendingIcon className="w-5  mr-2 " />
                  {/* </button> */}
                  <span className="lg:block hidden w-[60px]">Sort by:</span>
                </div>
                <FilterSelect
                  name="sort"
                  value={reset.sort}
                  options={
                    <>
                      <option className="text-gray-300" hidden>
                        Sort
                      </option>
                      <option value="post_create">Post creation date</option>
                      <option value="last_seen">Last seen</option>
                      {social !== 'facebook_library' && <>
                        <option value="video_views">Impression</option>
                        <option value="like">Likes</option>
                        <option value="comment">Comments</option>
                        <option value="share">Shares</option>
                      </>}
                      {social == 'facebook_library' && <>
                        <option value="ads_count">Ads Count</option>
                      </>}
                    </>
                  }
                  handleFilter={handleFilter}
                  htmlFor="sort"
                />
              </div>
            </div>
            {loading ? <div style={{ minHeight: 300 }}>
              <Loading />
            </div> :
              <ul
                id="header"
                className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4 "
                onMouseEnter={() => setPlaying(-1)}
              >
                {posts?.map((post, index, posts) => (
                  <li
                    key={post._id}
                    className="col-span-1 bg-white rounded-[8px] shadow w-full"
                  >
                    {/* {JSON.stringify(posts)} */}
                    <PostItem
                      post={post}
                      user={user}
                      posts={posts}
                      myList={myList}
                      setMyList={setMyList}
                      myDraftList={myDraftList}
                      setMyDraftList={setMyDraftList}
                      onMouseEnter={() => setPlaying(index)}
                      onMouseLeave={() => setPlaying(-1)}
                      playing={playing}
                      index={index}
                    />
                  </li>
                ))}
              </ul>}

          </>
        )}
        {/* {loading && !noResult && <Loading />} */}

        {!posts?.length <= 0 && (
          <button
            type="button"
            className="fixed right-5 bottom-5 inline-flex items-center p-3 border border-gray-300 shadow-sm text-base font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={scrollToTop}
          >
            <ArrowSmUpIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}
        <div className="flex items-center justify-center ">
          <div className="flex flex-col items-center">
            <div className="flex gap-3 flex-wrap p-6 py-8">
              <PaginationNav1
                gotoPage={setPageIndex}
                canPreviousPage={pageIndex > 0}
                canNextPage={pageIndex < countPages - 1}
                pageCount={countPages}
                pageIndex={pageIndex}
                setskip={setskip}
                setData={setData}
              />
            </div>
          </div>
        </div>
        <PermitionModal message="You have reached the maximum number of ads query number" viewModal={viewModal} setViewModal={setViewModal} />
      </div>
    </main>
  );
}
