import React, { useState, useEffect, Fragment } from "react";
import {
  CursorClickIcon,
  FlagIcon,
  PaperClipIcon,
  RefreshIcon,
  TranslateIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import {
  // FilterSelect,
  FilterMultiSelect,
} from "../../components/filter-select";
import MinReactions from "../../components/min-reactions";
import Calander from "../../components/Calander";
import { Menu, Transition } from "@headlessui/react";
import Searchbar from "../../components/Searchbar";
import RangeInput from "./RangeInput";
import { GiPriceTag } from 'react-icons/gi'
import { MdOutlineRestore } from "react-icons/md";
import ShippingIcon from '../../assets/icons/shipping.svg'
import DailyOrdersIcon from '../../assets/icons/daily-orders.svg'
import VotesIcon from '../../assets/icons/votes.svg'
import WishlistIcon from '../../assets/icons/wishlist.svg'
import NichesIcon from '../../assets/icons/niches.svg'
import TopCountiesIcon from '../../assets/icons/top-counties.svg'
import CategoryIcon from '../../assets/icons/category.svg'
import ShipFromIcon from '../../assets/icons/ship-from.svg'
import PriceIcon from '../../assets/icons/price.svg'
import TotalOrdersIcon from '../../assets/icons/total-orders.svg'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const countries = [
  { "label": "United Arab Emirates", "value": "AE" },
  { "label": "Australia", "value": "AU" },
  { "label": "Belgium", "value": "BE" },
  { "label": "Brazil", "value": "BR" },
  { "label": "Chile", "value": "CL" },
  { "label": "China", "value": "CN" },
  { "label": "Czech Republic", "value": "CZ" },
  { "label": "Germany", "value": "DE" },
  { "label": "Spain", "value": "ES" },
  { "label": "France", "value": "FR" },
  { "label": "Hungary", "value": "HU" },
  { "label": "Indonesia", "value": "ID" },
  { "label": "Israel", "value": "IL" },
  { "label": "Italy", "value": "IT" },
  { "label": "Japan", "value": "JP" },
  { "label": "South Korea", "value": "KR" },
  { "label": "Lithuania", "value": "LT" },
  { "label": "Latvia", "value": "LV" },
  { "label": "Nigeria", "value": "NG" },
  { "label": "Poland", "value": "PL" },
  { "label": "Russia", "value": "RU" },
  { "label": "Saudi Arabia", "value": "SA" },
  { "label": "Turkey", "value": "TR" },
  { "label": "Ukraine", "value": "UA" },
  { "label": "United Kingdom", "value": "UK" },
  { "label": "United States", "value": "US" },
  { "label": "Vietnam", "value": "VN" },
  { "label": "South Africa", "value": "ZA" }
]
export default function Filter({
  handleFilter,
  resetFilter,
  reset,
  setSelected,
  data,
  clear,
  setclear,
  handleSearch,
  handleCalander,
  handleCalanderSeenBetween,
}) {
  const location = useLocation();
  const [Category, setCategory] = useState([
    { label: "Bags Shoes", value: "Bags Shoes" },
    { label: "Jewelry Watches", value: "Jewelry Watches" },
    { label: "Vehicles", value: "Vehicles" },
    { label: "Fashion", value: "Fashion" },
    { label: "Beauty", value: "Beauty" },
    { label: "Events", value: "Events" },
    { label: "Phone Electronics", value: "Phone Electronics" },
    { label: "Business Finance", value: "Business Finance" },
    { label: "Home Garden", value: "Home Garden" },
    { label: "Health Fitness", value: "Health Fitness" },
  ]);
  useEffect(() => {
    if (location.pathname === "/admin") {
      setCategory((prev) => [
        ...prev,
        { label: "uncategory", value: "uncategory" },
      ]);
    }
  }, []);
  return (
    <div className=" max-w-6xl  py-3 sm:px-6  mx-auto ">
      <Searchbar handleSearch={handleSearch} />
      {/* <div className="w-full my-4 h-[1px] bg-gray-200" /> */}

      {/* <div className="w-full my-2 h-[1px] bg-gray-200" /> */}
      <div className="mt-5 grid grid-cols-2 gap-x-4 gap-y-2  md:grid-cols-3 lg:grid-cols-4 ">
        <FilterMultiSelect
          label={
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
              Site Type
            </>
          }
          options={[
            { label: "Shopify", value: "Shopify" },
            { label: "WordPress", value: "WordPress" },
            { label: "WooCommerce", value: "WooCommerce" },
            { label: "ClickFunnels", value: "ClickFunnels" },
            { label: "Webflow", value: "Webflow" },
            { label: "BigCommerce", value: "BigCommerce" },
            { label: "Wix", value: "Wix" },
            { label: "PrestaShop", value: "PrestaShop" },
          ]}
          htmlFor="platform"
          setSelected={setSelected}
          name="platform"
          clear={clear}
          setclear={setclear}
          data={data}

        />
        <FilterMultiSelect
          label={
            <>
              <img src={NichesIcon} />
              Niches
            </>
          }
          options={[
            { "label": "Acne", "value": "Acne" },
            { "label": "Whitening", "value": "Whitening" },
            { "label": "Bodybuilding", "value": "Bodybuilding" },
            { "label": "Boxing", "value": "Boxing" },
            { "label": "Yoga", "value": "Yoga" },
            { "label": "Wolf", "value": "Wolf" },
            { "label": "Wedding", "value": "Wedding" },
            { "label": "Tatto", "value": "Tatto" },
            { "label": "Swimming", "value": "Swimming" },
            { "label": "Skiing", "value": "Skiing" },
            { "label": "Sewing", "value": "Sewing" },
            { "label": "Running", "value": "Running" },
            { "label": "Painting", "value": "Painting" },
            { "label": "Parenting", "value": "Parenting" },
            { "label": "Fishing", "value": "Fishing" },
            { "label": "Hiking", "value": "Hiking" },
            { "label": "Hunting", "value": "Hunting" },
            { "label": "Gaming", "value": "Gaming" },
            { "label": "Garden", "value": "Garden" },
            { "label": "Kitchen", "value": "Kitchen" },
            { "label": "Gadget", "value": "Gadget" },
            { "label": "Dogs", "value": "Dogs" },
            { "label": "Cats", "value": "Cats" },
            { "label": "Cooking", "value": "Cooking" },
            { "label": "Cats", "value": "Cats" },
            { "label": "cars", "value": "cars" },
            { "label": "Camping", "value": "Camping" },
            { "label": "Furniture", "value": "Furniture" },
            { "label": "Lighting", "value": "Lighting" }
          ]}

          setSelected={setSelected}
          htmlFor="Niches"
          name="niches"
          clear={clear}
          setclear={setclear}
          data={data}
          // hasSelectAll={false}
          singleSelect={true}
        />
        <FilterMultiSelect
          label={
            <>
              <img src={CategoryIcon} />
              category
            </>
          }
          options={[
            { label: "Bags Shoes", value: "Bags Shoes" },
            { label: "Jewelry Watches", value: "Jewelry Watches" },
            { label: "Vehicles", value: "Vehicles" },
            { label: "Fashion", value: "Fashion" },
            { label: "Beauty", value: "Beauty" },
            { label: "Events", value: "Events" },
            { label: "Phone Electronics", value: "Phone Electronics" },
            { label: "Business Finance", value: "Business Finance" },
            { label: "Home Garden", value: "Home Garden" },
            { label: "Health Fitness", value: "Health Fitness" },
          ]}
          htmlFor="category"
          setSelected={setSelected}
          name="category"
          clear={clear}
          setclear={setclear}
          data={data}

        />
        <FilterMultiSelect
          label={
            <>
              <CursorClickIcon className="w-4 mr-2" />
              Social Media
            </>
          }
          options={[
            { label: "Facebook", value: "facebook" },
            { label: "Instagram", value: "instagram" },
            { label: "Tiktok", value: "tiktok" },
            { label: "Pinterest", value: "pinterest" },
          ]}
          htmlFor="social_media"
          setSelected={setSelected}
          name="social_media"
          clear={clear}
          setclear={setclear}
          data={data}

        />
        <RangeInput title={'Engagement:'} name={'engagement'} handleFilter={handleFilter} icon={<img src={WishlistIcon} />} />
        <RangeInput title={'Price'} name={'price'} handleFilter={handleFilter} icon={<img src={PriceIcon} />} />
        <Calander title={'Created between'} handleCalander={handleCalander} reset={reset} />
        <Calander title={'Seen between'} handleCalander={handleCalanderSeenBetween} reset={reset} />
      </div>

    </div>
  );
}
