import React from "react";
import { DateObject } from "react-multi-date-picker";
export default function DatePanel({
  setValue,
  datePickerRef,
  handleCalander,
  value,
}) {
  const chooseDate = (i) => {
    try {
      setValue([new DateObject().add(i, "days"), new DateObject()]);
      handleCalander(
        new DateObject().add(i, "days").toDate?.().toString(),
        new DateObject().toDate?.().toString()
      );
      datePickerRef.current.closeCalendar();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="grid ">
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(0);
        }}
        className="bg-white text-gray-700 hover:text-[#17A2B8]  focus:text-[#2b96a6]  hover:bg-gray-200 py-2 px-5  "
      >
        Today
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-1);
        }}
        className="bg-white  text-gray-700 hover:text-[#17A2B8]  focus:text-[#2b96a6]  hover:bg-gray-200 py-2 px-5 "
      >
        Yesterday
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-7);
        }}
        className="bg-white text-gray-700 hover:text-[#17A2B8]  focus:text-[#2b96a6]  hover:bg-gray-200 py-2 px-5 "
      >
        Last 7 days
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-14);
        }}
        className="bg-white text-gray-700 hover:text-[#17A2B8]  focus:text-[#2b96a6]  hover:bg-gray-200 py-2 px-5 "
      >
        Last 14 days
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-30);
        }}
        className="bg-white text-gray-700 hover:text-[#17A2B8]  focus:text-[#2b96a6]  hover:bg-gray-200 py-2 px-5 "
      >
        Last 30 days
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-60);
        }}
        className="bg-white text-gray-700 hover:text-[#17A2B8]  focus:text-[#2b96a6]  hover:bg-gray-200 py-2 px-5 "
      >
        Last 60 days
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          chooseDate(-90);
        }}
        className="bg-white text-gray-700 hover:text-[#17A2B8]  focus:text-[#2b96a6]  hover:bg-gray-200 py-2 px-5 "
      >
        Last 90 days
      </button>
    </div>
  );
}
