import { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
export function FilterSelect({
  label,
  name,
  value,
  options,
  className,
  handleFilter,
  htmlFor
}) {
  return (
    <div className={`${className ?? ""} flex flex-col w-full`}>
      {label && (
        <label
          htmlFor={htmlFor}
          className="text-sm font-regular text-gray-700 flex items-center mb-1 "
        >
          {label}
        </label>
      )}
      <select
        name={name}
        onChange={handleFilter}
        value={value}
        id="large"
        style={{outline:'none',boxShadow:'none'}}
        className="form-select block w-32 min-w-full px-2 py-1 text-base font-normal text-gray-700 border border-solid border-gray-300 rounded-[4px] transition ease-in-out  focus:text-gray-700 focus:bg-white focus:border-[#17A2B8]  outline-none"
      >
        {options}
      </select>
    </div>
  );
}
export function FilterMultiSelect({
  label,
  name,
  options,
  className,
  setSelected,
  htmlFor,
  clear,
  setclear,
  data,
  hasSelectAll = true,
  singleSelect = false
}) {
  const [val, setVal] = useState([]);

  const handle = (select) => {
    try {
      if (singleSelect === true) {
        setVal(
          select.length < 1
            ? select
            : [select[select.length - 1]]
        )
        setclear(false);
        setSelected({ name: name, value:  select.length < 1
          ? [] : [select[select.length - 1].value] })
      } else {
        setVal(select);
        setclear(false);
        let array = [];
        select.forEach((element) => {
          array.push(element.value);
        });
        setSelected({ name: name, value: array });
      }


    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (clear) {
      setVal([]);
    }
  }, [clear]);
  useEffect(() => {
    if (data?.[name]) {
      let array = []
      data?.[name]?.forEach(item => {
        array.push({ label: item, value: item })
      })
      setVal(array);
    }
  }, [data]);

  return (
    <div className={`${className ?? ""} flex flex-col w-full `}>
      {label && (
        <label
          htmlFor={htmlFor}
          className="text-sm font-regular text-gray-700 flex items-center mb-1"
        >
          {label}
        </label>
      )}
      <MultiSelect
        options={options}
        value={val}
        onChange={handle}
        hasSelectAll={hasSelectAll}
        // className={'border  border-gray-400 rounded-md overflow-hidden hover:border hover:border-red-400 hover:rounded-md'}
        // disableSearch
        closeOnChangedValue
        isCreatable={false}
      />
    </div>
  );
}
