import React from "react";
// import { Link } from 'react-router-dom'

export default function Button({ children, loginWithRedirect,className }) {
;

  return (
    <button
      onClick={() =>
        loginWithRedirect({
          screen_hint: "signup",
        })
      }
      className={`${
        className ? className : ""
      } inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-orange-500 px-4 py-2 text-base  text-white shadow-sm hover:bg-orange-600`}
    >
      {children}
    </button>
  );
}
