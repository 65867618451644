import React from 'react'
import { SearchIcon } from '@heroicons/react/solid'

export default function Search({handleSearch}) {
  return (
    <form onSubmit={handleSearch} className="max-w-lg w-full lg:max-w-xs">
        <label htmlFor="search" className="sr-only">
            Search by keywords
        </label>
        <div className="relative">
            <button type='submit' className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
            <input
                id="search"
                name="search"
                className="block w-full bg-gray-700 text-white border border-transparent rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-400 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 focus:placeholder-gray-500 sm:text-sm"
                placeholder="Search by keywords"
                type="search"
                required
            />
        </div>
    </form>
  )
}
