import {
  ChartBarIcon,
  LibraryIcon,
  ExternalLinkIcon,
  PlayIcon,
} from "@heroicons/react/outline";
import { useEffect, useState } from "react";
// import { dateFormat, lastseenDateCount } from "../controllers/actions";
import { BsFillBagCheckFill, BsFillHeartFill } from "react-icons/bs";
import { GoChecklist } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { kFormatter } from "../../controllers/actions";
import { fNumber, fShortenNumber } from "../../utils/formatNumber";
import FacebookIcon from '../../assets/icons/store/facebook.svg'
import InstagramIcon from '../../assets/icons/store/instagram.svg'
import PinterestIcon from '../../assets/icons/store/pinterest.svg'
import TiktokIcon from '../../assets/icons/store/tiktok.svg'
import CalenderIcon from '../../assets/icons/store/calender.svg'
import ImpressionIcon from '../../assets/icons/store/impression.svg'
import LikeIcon from '../../assets/icons/store/like.svg'
import CommentIcon from '../../assets/icons/store/comment.svg'
import ShareIcon from '../../assets/icons/store/share.svg'
// export function ProductIcon ({name,icon,value}){
//   return (
//     <div className="">
//       {icon}

//     </div>
//   )
// }
export default function ProductItem({ product, myList, setMyList, posts }) {
  const navigation = useNavigate()
  const [impressions, setImpressions] = useState(0)
  const [comments, setComments] = useState(0)
  const [likes, setLikes] = useState(0)
  const [shares, setShares] = useState(0)

  useEffect(() => {
    const postsRelated = product?.productsRelated?.filter(v => v.productModel === 'posts')
    const views = postsRelated.reduce((acc, obj) => {
      if (obj.product?.video_views == 0 || obj.product?.video_views == undefined) {
        return acc + (obj.product?.like * 220)
      } else {
        return acc + obj.product?.video_views
      }
    }, 0);
    setImpressions(views)
    const likes = postsRelated.reduce((acc, obj) => acc + obj.product?.like, 0);
    setLikes(likes)
    const comments = postsRelated.reduce((acc, obj) => acc + obj.product?.comment, 0);
    setComments(comments)
    const shares = postsRelated.reduce((acc, obj) => acc + obj.product?.share, 0);
    setShares(shares)
    return () => {
    };
  }, []);
  return (
    <div  >
      {/* {JSON.stringify(product?.analytic)} */}
      <div className="relative cursor-pointer" onClick={() => { navigation(product?._id) }}>
        <img
          className="flex-shrink-0 object-cover rounded-t-[23px] w-full h-[274px] mx-auto bg-slate-200"
          // src={`https://aas-bucket.s3.amazonaws.com/uploads/${post.resource.image}`}
          src={product?.productImage[0] || ''}
          alt=""
        />


        <div className="absolute bottom-[20px] right-[0px] w-[60px] h-[28px] bg-[#17A2B8] flex items-center justify-center rounded-l-[10px] " >
          <p className="font-[700] leading-[31px] text-white ">{fNumber(product?.price)}$</p>
        </div>
      </div>

      <div className="w-full m-auto px-[6px]">
        <p className="my-2 px-[9px] text-[#3C4344] text-[17px] font-[600] line-clamp-1 break-all cursor-pointer" onClick={() => { navigation(product?._id) }}>
          {product?.title}
        </p>
        <div className="mb-[7px] rounded-[13px]  px-3 py-4 flex flex-col items-center justify-center bg-white">
          {/* <ProductIcon /> */}

          <div className="w-full flex justify-between">
            <img src={PinterestIcon} />
            <img src={FacebookIcon} />
            <img src={InstagramIcon} />
            <img src={TiktokIcon} />
          </div>

          <div className="w-full my-2 h-[0.5px] bg-gray-200" />

          <div className="w-full flex  items-center justify-between">
            <div className="flex flex-col items-center">
              <p className="text-xs mb-1">First Seen</p>
              <img src={CalenderIcon} />
              <p className="text-xs  mt-1">{product?.post_create}</p>
            </div>
            <div className="w-4 my-2 h-[0.5px] bg-gray-200" />
            <p>{product?.productsRelated?.length} Ads</p>
            <div className="w-4 my-2 h-[0.5px] bg-gray-200" />
            <div className="flex flex-col items-center">
              <p className="text-xs mb-1">Last Seen</p>
              <img src={CalenderIcon} />
              <p className="text-xs mt-1">{product?.last_seen}</p>
            </div>
          </div>
          <div className="w-full my-2 h-[0.5px] bg-gray-200" />

          <div className="w-full flex items-center justify-between">
            <img src={ImpressionIcon} style={{ width: 20 }} />
            <div className="flex flex-col items-center">
              <p className="text-[10px]">{fShortenNumber(impressions)}</p>
              <p className="text-[8px]">Impression</p>
            </div>
            <img src={LikeIcon} style={{ width: 20 }} />
            <div className="flex flex-col items-center">
              <p className="text-[10px]">{fShortenNumber(likes)}</p>
              <p className="text-[8px]">Like</p>
            </div>
            <img src={CommentIcon} style={{ width: 20 }} />
            <div className="flex flex-col items-center">
              <p className="text-[10px]">{fShortenNumber(comments)}</p>
              <p className="text-[8px]">Comment</p>
            </div>
            <img src={ShareIcon} style={{ width: 20 }} />
            <div className="flex flex-col items-center">
              <p className="text-[10px]">{fShortenNumber(shares)}</p>
              <p className="text-[8px]">Share</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
