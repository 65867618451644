import axios from "axios";
import React, { useEffect, useState } from "react";

export function Upload(){
	const [f, setF] = useState({ preview: "", file: "" });
	const [res, setRes] = useState(null);
	const [count,setCount] = useState(0);

	const handleChange = (e) => {
		e.preventDefault();
		if (e.target.files.length) {
			setF({
				preview: URL.createObjectURL(e.target.files[0]),
				file: e.target.files[0],
			});
		}
	};

	let formData = new FormData();

	useEffect(() => {
		if(f.preview.length > 0){
			formData.append('file', f.file);
		}
	}, [formData, f])
	return (
		<div className="flex flex-col items-center justify-center p-10 h-[70vh]">
			<div className="text-2xl mb-10">Upload Tiktok File</div>
			<input className="mb-10" onChange={handleChange} type="file" id="file" name="file"/>
			<button 
				className="border rounded border-neutral-300 px-3 py-2"
				onClick={() => {
					if(f.file && count === 0){
						UploadData(formData, setRes);
						setCount(count+1)
					}
				}}
			>
				Upload to Db</button>
			{res && res.status === 200 ? (
				<div className="p-3 mt-2.5 text-green-600">{res.data}</div>
			) : res && (
				<div className="p-3 mt-2.5 text-red-600">{res.data}</div>
			)}	
		</div>
	)
}

async function UploadData(formData, setRes){
	console.log(formData.get('file'), 'fdt inside up d')
	await axios.post(`${process.env.REACT_APP_BASE_URL}/upload/tiktok/`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}
	).then(d => setRes(d))
}