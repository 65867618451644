import {
  ChartBarIcon,
  LibraryIcon,
  ExternalLinkIcon,
  PlayIcon,
} from "@heroicons/react/outline";
import { useState } from "react";
// import { dateFormat, lastseenDateCount } from "../controllers/actions";
import { BsFillBagCheckFill, BsFillHeartFill } from "react-icons/bs";
import { GoChecklist } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import AddToStoreBtn from "../../components/AddToStoreBtn";
import { kFormatter } from "../../controllers/actions";
import { fNumber, fShortenNumber } from "../../utils/formatNumber";

// export function ProductIcon ({name,icon,value}){
//   return (
//     <div className="">
//       {icon}

//     </div>
//   )
// }
export default function ProductItem({ product, user , store = false , onDeleteSuccess}) {
  const navigation = useNavigate()
  return (
    <div  >
      {/* {JSON.stringify(product?.analytic)} */}
      <div className="relative cursor-pointer" >
        {user?.role?.includes("admin") && (
          <div className="absolute top-2 right-2 bg-white rounded-full w-8 h-8 flex justify-center items-center">
            <AddToStoreBtn
              post={product}
              product_model={'aliexpress'}
              store={store}
              onDeleteSuccess={onDeleteSuccess}
            />
          </div>
        )}
        <img
          className="flex-shrink-0 object-cover rounded-[23px] w-full h-[274px] mx-auto bg-slate-200"
          // src={`https://aas-bucket.s3.amazonaws.com/uploads/${post.resource.image}`}
          src={product?.productImages[0]}
          alt=""
          onClick={() => { navigation(`/aliexpress_products/${product?._id}`) }}
        />

        <div className="flex flex-row items-center absolute bottom-1 left-1 px-2 rounded-full" style={{ backgroundColor: '#464646', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)' }}>
          <Rating emptyStyle={{ display: "flex", height: 24 }} fillColor={'#FFB600'} readonly initialValue={product?.reviewsRatings?.averageRating} size={20} fillStyle={{ display: "-webkit-inline-box" }} />
          <p className="ml-1.5 text-[16px] font-[900] leading-[31px]  text-[#FFB600]">{fShortenNumber(product?.reviewsRatings?.totalCount)}</p>
        </div>
        <div className="absolute bottom-[-5px] right-[-6px] w-[46px] h-[46px] flex items-center justify-center rounded-full p-2 backdrop-blur-[7.5px] " style={{ backgroundColor: 'rgba(255, 255, 255, 0.46)', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)' }}>
          <p className="font-[700] leading-[31px] text-[#0E26FE] ">{fNumber(product?.originalPrice?.value)}$</p>
        </div>
      </div>

      <div className="w-full m-auto px-[6px]">
        <p className="my-2 px-[9px] text-[#3C4344] text-[17px] font-[700] line-clamp-1 break-all cursor-pointer" onClick={() => { navigation(`/aliexpress/${product?._id}`) }}>
          {product?.title}
        </p>
        <div className="mb-[7px]  rounded-[13px] h-[86px]  flex gap-2 items-center justify-around bg-white">
          {/* <ProductIcon /> */}
          <div className="flex flex-col items-center py-3">
            <BsFillBagCheckFill size={20} color={'#0E26FE'} />
            <p className="font-[700] text-[8px] leading-[20px] text-[#0E26FE]">Order</p>
            <p className="font-[700] text-[8px] leading-[20px] text-[#0E26FE]">{product?.analytic[product?.analytic?.length - 1]?.orders < 0 ? 0 : product?.analytic[product?.analytic?.length - 1]?.orders}</p>
            {/* <p className="font-[700] text-[8px] leading-[20px] text-[#0E26FE]">{JSON.stringify(product?.analytic)}</p> */}
          </div>
          <div className="flex flex-col items-center py-3">
            <GoChecklist size={20} color={'#0E26FE'} />
            <p className="font-[700] text-[8px] leading-[20px] text-[#0E26FE]">Total Orders</p>
            <p className="font-[700] text-[8px] leading-[20px] text-[#0E26FE]">{fShortenNumber(product?.totalOrders)}</p>
          </div>
          <div className="flex flex-col items-center py-3">
            <BsFillHeartFill size={20} color={'#F00000'} />
            <p className="font-[700] text-[8px] leading-[20px] text-[#F00000]">wishlist</p>
            <p className="font-[700] text-[8px] leading-[20px] text-[#F00000]">{product?.analytic[product?.analytic?.length - 1]?.wishlist}</p>
          </div>
          <div className="flex flex-col items-center py-3">
            <BsFillHeartFill size={20} color={'#9747FF'} />
            <p className="font-[700] text-[8px] leading-[20px] text-[#9747FF]">Total wishlist</p>
            <p className="font-[700] text-[8px] leading-[20px] text-[#9747FF]">{fShortenNumber(product?.wishlistCount)}</p>
          </div>

        </div>
      </div>

    </div>
  );
}
