import React from 'react'
import { CalendarIcon, ChatIcon, EyeIcon, EyeOffIcon, ShareIcon, ThumbUpIcon } from '@heroicons/react/outline'
import { lastseenDateCount, SeenDate, kFormatter } from '../controllers/actions'
import { fShortenNumber } from '../utils/formatNumber'

const StatItem = ({ value, label, icon, iconColor, classN }) => {
	return (
		<div className={`flex flex-col items-center  w-full ${classN ?? ""}`}>
			<span className="text-m font-medium mb-1">{value}</span>
			<span className={`text-[11px] font-regular text-[#93999A]  flex justify-center items-center`}>{icon}{label}</span>
		</div>
	)
}

export default function PostStats({ post, fullStats }) {
	return (
		<div className='py-4 w-full'>

			<div className='flex justify-evenly w-full px-1'>
				<StatItem value={fullStats ? fShortenNumber(post?.video_views ? post?.video_views : post.like * 220) : fShortenNumber(post?.video_views ? post?.video_views : post.like * 220)} label="Impression" icon={<EyeIcon className='mr-1 w-4 ' color='#17A2B8' />} iconColor="text-red-500" />
				<StatItem value={fullStats ? post.like : kFormatter(post.like)} label="Likes" icon={<ThumbUpIcon className='mr-1 w-4' color='#17A2B8' />} iconColor="text-red-500" classN="border border-y-0 border-r-0 border-gray-200" />
				<StatItem value={fullStats ? post.comment : kFormatter(post.comment)} label="Comments" icon={<ChatIcon className='mr-1 w-4' color='#17A2B8' />} iconColor="text-teal-500" classN="border border-y-0 border-gray-200" />
				<StatItem value={fullStats ? post.share : kFormatter(post.share)} label="Shares" icon={<ShareIcon className='mr-1 w-4' color='#17A2B8' />} iconColor="text-orange-500" />
			</div>
			{
				fullStats &&
				<div className='flex justify-between mt-5 w-full'>
					<StatItem value={SeenDate(post?.post_create, post?.last_seen)} label="Creation date" icon={<EyeIcon className='mr-2 w-4' />} iconColor="text-gray-600" />
					<StatItem value={lastseenDateCount(post?.post_create)} label="Days Old" icon={<CalendarIcon className='mr-2 w-4' />} iconColor="text-gray-600" classN="border border-y-0 border-gray-200" />
					<StatItem value={SeenDate(post?.last_seen, post?.last_seen)} label="Last seen" icon={<EyeOffIcon className='mr-2 w-4' />} iconColor="text-gray-600" />
				</div>
			}
		</div>
	)
}
