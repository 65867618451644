import React, { useEffect, useState, useRef } from "react";
// import Filter from "./Filter";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowSmUpIcon,
  SearchIcon,
  SortDescendingIcon,
} from "@heroicons/react/outline";
// import PostItem from "./postItem";
// import Loading from "./loading";
import { FilterSelect } from "../../components/filter-select";
// import List from "./list";
// import List from "../../components/list";
// import Filter from "../../components/Filter";
import Loading from "../../components/loading";
import API from "../../API";
import ProductItem from "./ProductItem";
import Filter from "./Filter";
import List from "./list";
import PermitionModal from "../payment/PermissionModal";
import PaginationNav1 from "../../components/PaginatedItems";
// import { useSearchParams } from "react-router-dom";
export default function AliExpress() {
  const [posts, setPosts] = useState([]);
  const didMount = useRef(false);
  const [loading, setLoading] = useState(false);
  const [noResult, setnoResult] = useState(false);
  const [skip, setskip] = useState(0);
  //   const [social, setSocial] = useState('facebook')
  const [data, setData] = useState({
    skip: 0,
    sort: "createdAt",
    fetured: true,
    social_type: 'facebook'
  });
  const [reset, setReset] = useState({});
  const { user, getAccessTokenSilently } = useAuth0();
  const [myList, setMyList] = useState([]);
  const [selected, setSelected] = useState();
  const [clear, setclear] = useState(false);
  const [Search, setSearch] = useState();
  const [searchType, setSearchType] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [countPages, setCountPages] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const pageItems = 15
  // const [searchParams, setSearchParams] = useSearchParams();
  const box = document.getElementById("box");

  // console.log(JSON.parse(searchParams.get("filter")));

  const handleFilter = (event) => {
    try {
      event.preventDefault();
      setPosts([]);
      setPageIndex(0)
      setCountPages(0)
      setnoResult(false);
      let name = event.target.name;
      let value = event.target.value;
      if (name == 'sort') {
        setData({
          ...data, [name]: value, skip: 0
        });
      } else {
        setData({
          ...data, [name]: {
            from: event?.target?.from?.value,
            to: event?.target?.to?.value
          }, skip: 0
        });
      }

      setReset({ ...reset, [name]: value });
      setskip(0);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selected?.name) {
      setPosts([]);
      setPageIndex(0)
      setCountPages(0)
      setnoResult(false);
      setData((data) => ({
        ...data,
        [selected?.name]: selected?.value,
        skip: 0,
      }));
      setskip(0);
    }
  }, [selected]);

  const resetFilter = () => {
    try {
      setPosts([]);
      setclear(true);
      setPageIndex(0)
      setCountPages(0)
      setskip(0);
      setData({ skip: 0, sort: "createdAt" });
      setReset({
        sort: "createdAt",
      });
      document.getElementById("search").value = "";
      document.getElementById("min").value = "";
      document.getElementById("reaction").value = "like";
    } catch (error) {
      console.log(error);
    }
  };
  document.getElementById("logo")?.addEventListener("click", resetFilter);
  useEffect(() => {
    if (Search) {
      setPosts([]);
      setPageIndex(0)
      setCountPages(0)
      setnoResult(false);
      setData((data) => ({ ...data, search: Search, search_type: searchType, skip: 0 }));
      setskip(0);
    }
  }, [Search, searchType]);

  // const handleScroll = (event) => {
  //   const el = event.target.documentElement;
  //   if (
  //     el.scrollHeight - el.scrollTop < el.scrollHeight / 2 &&
  //     !loading &&
  //     !noResult
  //   ) {
  //     setLoading(true);
  //     setskip(skip + 1);
  //   }
  // };
  // window.addEventListener("scroll", handleScroll);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    resetFilter();
  }, []);

  // useEffect(() => {
  //   if (skip !== 0) {
  //     setData((data) => ({ ...data, skip: skip }));
  //   }
  // }, [skip]);

  // useEffect(() => {
  //   setPosts((data) =>
  //     data.filter(
  //       (item) =>
  //         new Date().getTime() - new Date(item.created_at).getTime() > 90000
  //     )
  //   );
  // }, [loading]);

  useEffect(() => {
    (async () => {
      // console.log("first");
      try {
        setLoading(true);
        console.log(`filter=${JSON.stringify(data)}`)
        // setSearchParams(`filter=${JSON.stringify(data)}`);
        const updUser ={...user};
        delete updUser['picture']
        const res = await API.get(`aliexpress?user=${JSON.stringify(updUser)}&data=${JSON.stringify(data)}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        });

        setPosts([...res?.data?.products]);
        setPageIndex(data.skip)
        setCountPages(Math.round(Number(res?.data?.countDocuments) / pageItems))
        setLoading(false);
        if (res.data?.length === 0) {
          setnoResult(true);
        }
      } catch (error) {
        if (error?.response?.status === 429) {
          setViewModal(true)
        }
        setLoading(false);
        console.log(error);
      }
    })();
  }, [data]);
  // useEffect(()=>{
  //   setPosts([])
  // },[posts])
  useEffect(() => {
    (async () => {
      try {
        // let filter = JSON.parse(searchParams.get("filter")) || {}
        // if (Object.keys(filter).length > 3) {
        //   console.log(Object.keys(filter).length)
        //   // setPosts([])
        //   // setData(filter)
        // }

        // console.log(filter);
        API.get(`favorites?email=${user.email}`, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }).then((res) => {
          setMyList([]);
          res.data.forEach((element) => {
            setMyList((prev) => [...prev, element.post?._id]);
          });
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const handleSearch = (e, searchType) => {
    try {
      e.preventDefault();

      let search = e.target.search.value
        .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")

      setSearchType(searchType)
      setSearch(search);
    } catch (error) {
      console.log(error);
    }
  };
  //   const handleSocial = (name) => {
  //     if (social !== name) {
  //       setPosts([])
  //       setSocial(name)
  //       setnoResult(false);
  //       setData((prev) => ({ ...prev, skip: 0}))
  //       setskip(0);
  //     }
  //   };
  return (
    <main
      className=" relative"
      style={{ height: "calc(100% - 112px)" }}
    >
      <div className="h-full w-full pt-3 bg-white">
        <Filter
          handleFilter={handleFilter}
          resetFilter={resetFilter}
          reset={reset}
          setSelected={setSelected}
          data={data}
          setclear={setclear}
          clear={clear}
          handleSearch={handleSearch}
        />
      </div>

      <div className="h-full flex flex-col max-w-8xl mx-auto px-4 sm:px-6 lg:px-10">
        {posts?.length <= 0 && !loading ? (
          <div className="mt-3">
            <List
              data={data}
              setReset={setReset}
              setData={setData}
              setPosts={setPosts}
              setSelected={setSelected}
              setskip={setskip}
              resetFilter={resetFilter}
            />
            <div className="text-center h-full flex flex-col mt-3  justify-center items-center min-h-[250px]">
              <div className="mb-3 p-3 bg-slate-200 rounded-full">
                <SearchIcon className="w-6 text-gray-400" />
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900 ">
                No result found
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Try diffirent keyword
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between mt-2 mb-2">
              {box.childNodes?.length === 0 && (
                <h2 className="text-2xl font-medium text-zinc-900">
                  {/* Items list products: */}
                </h2>
              )}
              <List
                data={data}
                setReset={setReset}
                setData={setData}
                setPosts={setPosts}
                setSelected={setSelected}
                setskip={setskip}
                resetFilter={resetFilter}
              />
              <div className="flex   items-center ">
                <div className="mr-2 flex items-center w-full text-gray-600">
                  {/* <button className="form-select flex-col mr-2 p-2  text-base font-normal text-gray-700 border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 bg-white focus:border-orange-600 focus:outline-none"> */}
                  <SortDescendingIcon className="w-5  mr-2 " />
                  {/* </button> */}
                  <span className="lg:block hidden w-[60px]">Sort by:</span>
                </div>
                <FilterSelect
                  name="sort"
                  value={reset.sort}
                  options={
                    <>
                      <option className="text-gray-300" hidden>
                        Sort
                      </option>
                      <option value="createdAt">Post creation date</option>
                      <option value="updatedAt">Last seen</option>
                      <option value="totalOrders">Total Orders</option>
                      <option value="wishlistCount">Total Wishlist</option>
                    </>
                  }
                  handleFilter={handleFilter}
                  htmlFor="sort"
                />
              </div>
            </div>
            {loading ? <div style={{ minHeight: 300 }}>
              <Loading />
            </div> :
              <ul
                id="header"
                className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-5 "
              >
                {posts?.map((post, index, posts) => (
                  <li
                    key={index}
                    className="col-span-1 bg-[#F1F1F1] rounded-[23px] "
                  >
                    {/* {JSON.stringify(posts)} */}
                    <ProductItem
                      product={post}
                      user={user}
                    // posts={posts}
                    // myList={myList}
                    // setMyList={setMyList}
                    />
                  </li>
                ))}
              </ul>}
          </>
        )}
        {/* {loading && !noResult && <Loading />} */}
        {!posts?.length <= 0 && (
          <button
            type="button"
            className="fixed right-5 bottom-5 inline-flex items-center p-3 border border-gray-300 shadow-sm text-base font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={scrollToTop}
          >
            <ArrowSmUpIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}
        <div className="flex items-center justify-center ">
          <div className="flex flex-col items-center">
            <div className="flex gap-3 flex-wrap p-6 py-8">
              <PaginationNav1
                gotoPage={setPageIndex}
                canPreviousPage={pageIndex > 0}
                canNextPage={pageIndex < countPages - 1}
                pageCount={countPages}
                pageIndex={pageIndex}
                setskip={setskip}
                setData={setData}
              />
            </div>
          </div>
        </div>
        <PermitionModal message="You have reached the maximum number of products query number" viewModal={viewModal} setViewModal={setViewModal} />
      </div>
    </main>
  );
}
