import "./App.css";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Favorites from "./components/Favorite";

import { useEffect, useState } from "react";
import LandingPage from "./components/landing-page";
import PostTable from "./components/admin/post-table";
import AliExpress from "./pages/ali-express/AliExpress";
import ProductDetails from "./pages/ali-express/ProductDetails";
import ProductDetailsStore from "./pages/store/ProductDetails";
import Store from "./pages/store/Store";
import Draft from "./pages/draft/Draft";
import PlansPage from "./pages/payment/Plans";
import Footer from "./components/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";
import { Upload } from "./pages/upload";
import Terms from "./pages/terms";



function App() {
  const [Search, setSearch] = useState();
  const { isAuthenticated, isLoading, user } = useAuth0();

  // useEffect(()=>{
  //   console.log('isAuthenticated' + isAuthenticated)
  // },[])
  if (isLoading) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
        <p className="text-sm">Loading...</p>
      </div>
    );
  }
  const handleSearch = (e) => {
    try {
      e.preventDefault();

      let search = e.target.search.value
        .replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")

      setSearch(search);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="h-full bg-slate-100">
        <Router>
          {isAuthenticated === true ? (
            <>
              <NavBar handleSearch={handleSearch} />
              <div className="mt-[60px] ">
                <Routes>
                  <Route exact path="/" element={<Home fetured={false} />} />
                  <Route path="/upload" element={<Upload />} />
                  <Route exact path="/ads_staff_picks" element={<Home fetured={true} />} />
                  <Route exact path="/favorites" element={<Favorites />} />
                  <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
                  <Route exact path="/terms" element={<Terms />} />
                  <Route exact path="/contact_us" element={<ContactUs />} />
                  <Route exact path="/aliexpress_products" element={<AliExpress />} />
                  <Route path="/aliexpress_products/:productId" element={<ProductDetails />} />
                  <Route exact path="/products_Research" element={<Store />} />
                  <Route path="/products_Research/:productId" element={<ProductDetailsStore />} />
									<Route exact path="/payment" element={<PlansPage />} />
                  {isAuthenticated && user?.role?.includes("admin") && <Route exact path="/admin" element={<PostTable Search={Search} />} />}
                  {isAuthenticated && user?.role?.includes("admin") && <Route exact path="/draft" element={<Draft />} />}
                </Routes>
                <Footer />
              </div>
            </>
          ) : (
            <Routes>
              <Route exact path="/payment" element={<PlansPage />} />
              <Route exact path="*" element={<LandingPage />} />
            </Routes>
          )}


        </Router>
      </div>
    </div>

  );
}

export default App;
