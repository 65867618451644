import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import API from '../../API';
import Loading from '../../components/loading';
import ImageSlider from './ImageSlider';
import CopyIcon from '../../assets/icons/copy.svg'
import Date from '../../assets/icons/date.svg'
import Daily from '../../assets/icons/daily.svg'
import OrderIcon from '../../assets/icons/d-order.svg'
import WishlistIcon from '../../assets/icons/d-wishlist.svg'
import TotalOrdersIcon from '../../assets/icons/d-total-orders.svg'
import TotalWishlistIcon from '../../assets/icons/d-total-wishlist.svg'
import VoteIcon from '../../assets/icons/d-vote.svg'
import PriceIcon from '../../assets/icons/d-price.svg'
import ReviewIcon from '../../assets/icons/d-review.svg'
import ShippingIcon from '../../assets/icons/d-shipping.svg'

import OrdersIcon from '../../assets/icons/store/orders.svg'
import ImpressionsIcon from '../../assets/icons/store/impressions.svg'
import LikesIcon from '../../assets/icons/store/likes.svg'
import ProfitIcon from '../../assets/icons/store/profit.svg'
import SellingIcon from '../../assets/icons/store/selling.svg'
import CommentsIcon from '../../assets/icons/store/comments.svg'
import SharesIcon from '../../assets/icons/store/shares.svg'
import ProductCostIcon from '../../assets/icons/store/product-cost.svg'
import { fShortenNumber } from '../../utils/formatNumber';
import { lastseenDateCount, SeenDate } from '../../controllers/actions';
import PostItem from '../../components/postItem';
import ProductItem from '../ali-express/ProductItem';

const IconCard = ({ name, icon, count }) => {
    return (
        <div className="col-span-6 lg:col-span-3 bg-white flex items-center justify-evenly px-2  h-[92px] rounded-md " style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} >
            <img src={icon} />
            <div>
                <p className='text-[#383C3D] text-[28px] leading-8 font-[500] text-center'>{count}</p>
                <p className='text-[#828080] text-[16px] font-[400] text-center'>{name}</p>
            </div>
        </div>
    )
}
const IconCard_2 = ({ name, icon, count }) => {
    return (
        <div className="col-span-6 lg:col-span-3 bg-white flex items-center justify-evenly px-6 w-56  h-[92px] rounded-md  border-2 border-black"  >
            <img src={icon} />
            <div>
                <p className='text-[#383C3D] text-[28px] leading-8 font-[500] text-center'>{count}</p>
                <p className='text-[#828080] text-[16px] font-[400] text-center'>{name}</p>
            </div>
        </div>
    )
}
const ProductDetails = () => {
    let { productId } = useParams();
    const { user, getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true)
    const [product, setProduct] = useState()
    const [myList, setMyList] = useState([]);
    const [posts, setPosts] = useState([]);
    // const [socialType, setSocialType] = useState('facebook');
    const [productModel, setProductModel] = useState('posts');
    const [dailyWishlist, setDailyWishlist] = useState('')
    const [votes, setVotes] = useState('')
    const [price, setPrice] = useState('')
    const [totalOrders, setTotalOrders] = useState('')
    const [profitMargin, setProfitMargin] = useState('')
    const [sellingPrice, setSellingPrice] = useState('')
    const [productCost, setProductCost] = useState('')
    const [impressions, setImpressions] = useState('')
    const [likes, setLikes] = useState('')
    const [comments, setComments] = useState('')
    const [shares, setShares] = useState('')
    const [facebookCount, setFacebookCount] = useState('')
    const [tiktokCount, setTiktokCount] = useState('')
    const [pinterestCount, setPinterestCount] = useState('')
    const [instagramCount, setInstagramCount] = useState('')
    const [socialImpressions, setSocialImpressions] = useState('')
    const [socialLikes, setSocialLikes] = useState('')
    const [socialtComments, setSocialComments] = useState('')
    const [socialShares, setSocialShares] = useState('')
    const [playing, setPlaying] = useState(-1);
    const [aliexpressCount, setAliexpressCount] = useState('')

    useEffect(() => {
        (async () => {
            try {
                API.get(`store/${productId}`, {
                    headers: {
                        Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    },
                },
                ).then((res) => {
                    console.log(res.data);
                    setProduct(res.data);
                    const postsRelated = res.data.productsRelated.filter(v => v.productModel === 'posts')

                    // const views = postsRelated.reduce((acc, obj) => acc + obj.product.views, 0);
                    const views = postsRelated.reduce((acc, obj) => {
                        if (obj.product?.video_views == 0 || obj.product?.video_views == undefined) {
                          return acc + (obj.product?.like * 220)
                        } else {
                          return acc + obj.product?.video_views
                        }
                      }, 0);
                    //   setImpressions(views)
                    setImpressions(views)
                    const likes = postsRelated.reduce((acc, obj) => acc + obj.product.like, 0);
                    setLikes(likes)
                    const comments = postsRelated.reduce((acc, obj) => acc + obj.product.comment, 0);
                    setComments(comments)
                    const shares = postsRelated.reduce((acc, obj) => acc + obj.product.share, 0);
                    setShares(shares)

                    const facebookPosts = postsRelated.filter(v => !(['tiktok', 'instagram', 'pinterest', 'facebook_library'].includes(v.product.social_type)))
                    setFacebookCount(facebookPosts.length)
                    socialCountFilter(facebookPosts)

                    const instagramPosts = postsRelated.filter(v => v.product.social_type === 'instagram')
                    setInstagramCount(instagramPosts.length)
                    const tiktokPosts = postsRelated.filter(v => v.product.social_type === 'tiktok')
                    setTiktokCount(tiktokPosts.length)
                    const pinterestPosts = postsRelated.filter(v => v.product.social_type === 'pinterest')
                    setPinterestCount(pinterestPosts.length)

                    const aliexpressRelated = res.data.productsRelated.filter(v => v.productModel === 'aliexpress')
                    const totalOrders = aliexpressRelated.reduce((acc, obj) => acc + obj.product.totalOrders, 0);
                    setTotalOrders(totalOrders)
                    const shippingCostAvg = aliexpressRelated.reduce((acc, obj) => acc + obj.product.shippingCost, 0) / aliexpressRelated.length;
                    setProductCost(shippingCostAvg)
                    const priceAvg = aliexpressRelated.reduce((acc, obj) => acc + obj.product.originalPrice.value, 0) / aliexpressRelated.length;
                    setSellingPrice(priceAvg)
                    // const wishlistCount = aliexpressRelated.reduce((acc, obj) => acc + obj.product.wishlistCount, 0);
                    // setTotalWishlist(wishlistCount)
                    // const averageRating = aliexpressRelated.reduce((acc, obj) => acc + obj.product.reviewsRatings.averageRating, 0);
                    // setReviews(averageRating)
                    // const totalCount = aliexpressRelated.reduce((acc, obj) => acc + obj.product.reviewsRatings.totalCount, 0);
                    // setReviews(totalCount)
                    setAliexpressCount(aliexpressRelated.length)
                    console.log(aliexpressRelated)
                    setPosts(facebookPosts);
                    setLoading(false)
                });
            } catch (error) {
                console.log(error);
            }
        })()
    }, [productId]);
    // useEffect(() => {

    //     return () => { };
    // }, [socialType, productModel]);
    useEffect(() => {
        (async () => {
            try {
                API.get(`favorites?email=${user.email}`, {
                    headers: {
                        Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    },
                }).then((res) => {
                    setMyList([]);
                    res.data.forEach((element) => {
                        setMyList((prev) => [...prev, element.post?._id]);
                    });
                });
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);
    const socialCountFilter = (socialPost) => {
        // const socialviews = socialPost.reduce((acc, obj) => acc + obj.product.views, 0);
        const socialviews = socialPost.reduce((acc, obj) => {
            if (obj.product?.video_views == 0 || obj.product?.video_views == undefined) {
              return acc + (obj.product?.like * 220)
            } else {
              return acc + obj.product?.video_views
            }
          }, 0);
        setSocialImpressions(socialviews)
        const sociallikes = socialPost.reduce((acc, obj) => acc + obj.product.like, 0);
        setSocialLikes(sociallikes)
        const socialcomments = socialPost.reduce((acc, obj) => acc + obj.product.comment, 0);
        setSocialComments(socialcomments)
        const socialshares = socialPost.reduce((acc, obj) => acc + obj.product.share, 0);
        setSocialShares(socialshares)
    }
    const postFilter = (_productModel, _socialType) => {
        if (product?.productsRelated) {
            // alert(productModel)
            // alert(socialType)
            setLoading(true)
            setPosts([])
            setProductModel(_productModel)
            const postsRelated = product?.productsRelated?.filter(v => v.productModel === _productModel)
            if (_productModel === 'posts') {
                if (_socialType === 'facebook') {
                    const facebookPosts = postsRelated.filter(v => !(['tiktok', 'instagram', 'pinterest', 'facebook_library'].includes(v.product.social_type)))
                    socialCountFilter(facebookPosts)
                    setPosts([...facebookPosts]);
                    // alert(JSON.stringify(facebookPosts))
                } else {
                    const socialTypePosts = postsRelated.filter(v => v.product.social_type === _socialType)
                    socialCountFilter(socialTypePosts)
                    setPosts([...socialTypePosts]);
                    // alert(JSON.stringify(socialTypePosts))
                }
            } else if (_productModel === 'aliexpress') {
                console.log(postsRelated)
                setPosts(postsRelated);
            }
            setLoading(false)
        }
    }
    if (loading) {
        return <div className='py-20'>
            <Loading />
        </div>
    }
    return (
        <div className='container  mx-auto max-w-max lg:px-20 pt-6 ' onMouseEnter={() => setPlaying(-1)}>
            {/* {JSON.stringify(product)} */}
            <div className="grid grid-cols-12 gap-6">
                <div className="lg:col-span-4 col-span-12 bg-white overflow-hidden rounded-md">
                    <ImageSlider images={product?.productImage} />
                </div>
                <div className="lg:col-span-8 col-span-12 flex flex-col justify-between">
                    <div className='flex items-center justify-between lg:flex-row flex-col bg-white w-full  rounded-md py-5 px-5'>
                        <div className='lg:w-9/12 w-full'>
                            <div className='flex items-center'>
                                <p className='text-[#5E5E5E] text-[24px]  font-[700] line-clamp-1 break-all capitalize mix-blend-normal'>
                                    {product?.title}
                                </p>
                                <img src={CopyIcon} className='cursor-pointer mr-4' style={{ width: 28, height: 28 }} onClick={() => navigator.clipboard.writeText(product?.title)} />
                            </div>
                            <div className='flex items-center gap-4 mt-2'>
                                <div className='flex items-center gap-2'>
                                    <img src={Date} />
                                    <p className='text-lg text-[#6B7677] '>Created {lastseenDateCount(product?.createdAt)} days ago</p>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <img src={Daily} />
                                    <p className='text-lg text-[#6B7677] '>Last seen: 1 days ago</p>
                                </div>
                            </div>
                        </div>
                        <button className='text-lg w-48 h-16 bg-[#0F92A7] hover:bg-[#13a0b6] text-white rounded-lg'>Add to Store</button>
                    </div>
                    <div className="bg-white grid grid-cols-12 rounded-md gap-x-6 gap-y-8 p-6 ">
                        <IconCard name={'Orders'} count={fShortenNumber(totalOrders)} icon={OrdersIcon} />
                        <IconCard name={'Product cost'} count={'$' + fShortenNumber(productCost)} icon={ProductCostIcon} />
                        <IconCard name={'Selling price'} count={'$' + fShortenNumber(sellingPrice)} icon={SellingIcon} />
                        <IconCard name={'Profit margin'} count={'$' + fShortenNumber(price)} icon={ProfitIcon} />
                        <IconCard name={'impressions'} count={fShortenNumber(impressions)} icon={ImpressionsIcon} />
                        <IconCard name={'Likes'} count={fShortenNumber(likes)} icon={LikesIcon} />
                        <IconCard name={'Comments'} count={fShortenNumber(comments)} icon={CommentsIcon} />
                        <IconCard name={'Shares'} count={fShortenNumber(shares)} icon={SharesIcon} />
                    </div>
                    <div className="bg-white grid grid-cols-12 rounded-md gap-4 p-6 place-items-center">
                        <div className="md:col-span-3 col-span-12 ">
                            <a href={product?.landingPage} target={'_blank'}>
                                <button className='w-48 h-12 text-[#818181] text-[16px] font-[400] text-center rounded-lg border border-[#DB4848] hover:bg-[#0F92A7] hover:text-white'
                                >
                                    Landing Page
                                </button>
                            </a>

                        </div>
                        <div className="md:col-span-3 col-span-12">
                            <button className='w-48 h-12 text-[#818181] text-[16px] font-[400] text-center rounded-lg border border-[#D9C03C] hover:bg-[#0F92A7] hover:text-white'>
                                Find on Aliexpress</button>
                        </div>
                        <div className="md:col-span-3 col-span-12">
                            <button className='w-48 h-12 text-[#818181] text-[16px] font-[400] text-center rounded-lg border border-[#121313] hover:bg-[#0F92A7] hover:text-white'>
                                Find on Amazon</button>
                        </div>
                        <div className="md:col-span-3 col-span-12">
                            <button className='w-48 h-12 text-[#818181] text-[16px] font-[400] text-center rounded-lg border border-[#62C3D3] hover:bg-[#0F92A7] hover:text-white'>
                                Find On Shopify Stores</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5 bg-white grid grid-cols-10 rounded-md gap-4 p-6 place-items-center">
                <div className="md:col-span-2 col-span-12 ">
                    <button onClick={() => {

                        postFilter('posts', 'facebook')
                        // setProductModel('posts')
                        // setSocialType('facebook')
                    }} className='w-56 h-10 text-[#818181] text-[16px] font-[400] text-center rounded-lg border border-[#0E26FE] hover:bg-[#0F92A7] hover:text-white'>
                        Facebook Ads ({facebookCount})</button>
                </div>
                <div onClick={() => {
                    postFilter('posts', 'tiktok')
                    // setProductModel('posts')
                    // setSocialType('tiktok')
                }} className="md:col-span-2 col-span-12">
                    <button className='w-56 h-10 text-[#818181] text-[16px] font-[400] text-center rounded-lg border border-[#2B2B2B] hover:bg-[#0F92A7] hover:text-white'>
                        Tiktok Ads ({tiktokCount})</button>
                </div>
                <div onClick={() => {
                    // setProductModel('posts')
                    // setSocialType('pintrest')
                    postFilter('posts', 'pinterest')
                }} className="md:col-span-2 col-span-12">
                    <button className='w-56 h-10 text-[#818181] text-[16px] font-[400] text-center rounded-lg border border-[#B81717] hover:bg-[#0F92A7] hover:text-white'>
                        Pintrest Ads ({pinterestCount})</button>
                </div>
                <div className="md:col-span-2 col-span-12">
                    <button onClick={() => {
                        // setProductModel('posts')
                        // setSocialType('instagram')
                        postFilter('posts', 'instagram')
                    }} className='w-56 h-10 text-[#818181] text-[16px] font-[400] text-center rounded-lg border border-[#B81781] hover:bg-[#0F92A7] hover:text-white'>
                        Instagram Ads ({instagramCount})</button>
                </div>
                <div className="md:col-span-2 col-span-12">
                    <button onClick={() => {
                        setProductModel('aliexpress')
                        postFilter('aliexpress', '')
                    }} className='w-56 h-10 text-[#818181] text-[16px] font-[400] text-center rounded-lg border border-[#A8A100] hover:bg-[#0F92A7] hover:text-white'>
                        Aliexpress ({aliexpressCount})</button>
                </div>
            </div>
            {productModel === 'posts' && (
                <div className="w-full mt-5 px-36 py-10 bg-white flex justify-between">
                    <IconCard_2 name={'Impressions'} count={fShortenNumber(socialImpressions)} icon={ImpressionsIcon} />
                    <IconCard_2 name={'Likes'} count={fShortenNumber(socialLikes)} icon={LikesIcon} />
                    <IconCard_2 name={'Comments'} count={fShortenNumber(socialtComments)} icon={CommentsIcon} />
                    <IconCard_2 name={'Shares'} count={fShortenNumber(socialShares)} icon={SharesIcon} />
                </div>
            )}

            <ul
                id="header"
                className={`grid grid-cols-1 mt-5 gap-5 sm:grid-cols-2 md:grid-cols-3  ${productModel === 'aliexpress' ? "lg:grid-cols-5" : "lg:grid-cols-4"} mb-10`}
                onMouseEnter={() => setPlaying(-1)}
            >
                {posts.length > 0 && productModel === 'posts' ?
                    posts?.map((post, index, posts) => (
                        <li
                            key={index}
                            className="col-span-1 bg-white rounded-[8px] shadow w-full"
                        >
                            <PostItem
                                post={post.product}
                                user={user}
                                posts={posts}
                                myList={myList}
                                setMyList={setMyList}
                                store={true}
                                onDeleteSuccess={() => {
                                    let updatedProducts = product
                                    updatedProducts.productsRelated = updatedProducts.productsRelated.filter(v => v.product._id !== post.product._id)
                                    setProduct(updatedProducts)
                                    const updatedPosts = posts.filter(v => v.product._id !== post.product._id)
                                    setPosts([...updatedPosts])
                                }}
                                onMouseEnter={() => setPlaying(index)}
                                onMouseLeave={() => setPlaying(-1)}
                                playing={playing}
                                index={index}
                            />

                        </li>
                    )) : posts.length > 0 && productModel === 'aliexpress' && posts?.map((post, index, posts) => (
                        <li
                            key={index}
                            className="col-span-1 bg-white rounded-[23px] shadow w-full"
                        >
                            <ProductItem
                                product={post.product}
                                user={user}
                                store={true}
                                onDeleteSuccess={() => {
                                    let updatedProducts = product
                                    updatedProducts.productsRelated = updatedProducts.productsRelated.filter(v => v.product._id !== post.product._id)
                                    setProduct(updatedProducts)
                                    const updatedPosts = posts.filter(v => v.product._id !== post.product._id)
                                    setPosts([...updatedPosts])
                                }}
                            />
                        </li>
                    ))}
            </ul>

        </div>
    );
}

export default ProductDetails;
