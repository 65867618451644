import React from "react";
import { Fragment, useRef, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
    FlagIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    XIcon,
    ExternalLinkIcon,
} from "@heroicons/react/outline";
import { createPortal } from "react-dom";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
export default function PermitionModal({ viewModal, setViewModal, message, productModal = false }) {
    const cancelButtonRef = useRef(null);
    const { user } = useAuth0()
    const navigator = useNavigate()
    return createPortal(
        <React.Fragment>
            <Transition appear show={viewModal} as={Fragment}>
                <Dialog as="div" className="relative z-50 " onClose={() => {!productModal && setViewModal(false)}}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto ">
                        <div className="flex min-h-full  items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 pt-4 pb-2"
                                    >
                                        The number of views has reached the maximum
                                    </Dialog.Title>
                                    <div className="mt-2 mb-4">
                                        <p className="text-sm text-gray-500">
                                            {message}
                                        </p>
                                    </div>

                                    <div className="flex flex-row  items-center justify-evenly mt-6 mb-1">
                                        {productModal ? (
                                            <>
                                                <Link to={'/'}>
                                                    <button
                                                        type="button"
                                                        className=" px-8 py-2  shadow-sm text-sm font-medium rounded-md 
                                                    bg-[#17A2B8] hover:bg-[#118a9c] text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#17A2B8]"
                                                    >
                                                        Home Page
                                                    </button>
                                                </Link>
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-black hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300 focus-visible:ring-offset-2"
                                                    onClick={() => navigator(-1)}
                                                >
                                                    Go Back
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                {user?.role[0] === 'trial' && (
                                                    <Link to={'/payment'}>
                                                        <button
                                                            type="button"
                                                            className=" px-8 py-2  shadow-sm text-sm font-medium rounded-md 
                                                    bg-[#17A2B8] hover:bg-[#118a9c] text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#17A2B8]"
                                                        >
                                                            Upgrade Your Plan
                                                        </button>
                                                    </Link>
                                                )}

                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-black hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-300 focus-visible:ring-offset-2"
                                                    onClick={() => setViewModal(false)}
                                                >
                                                    {user?.role[0] !== 'trial' ? 'Close' : 'No thanks!'}
                                                </button>
                                            </>
                                        )}


                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </React.Fragment>,
        window.modals
    );
}

